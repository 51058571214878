/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { cloneElement } from 'react';
import { ErrorsState, QueriesState } from 'redux-query';
import { Formik, FormikValues, FormikHelpers, FormikProps } from 'formik';
import { Box, LinearProgress, ButtonProps, useTheme, Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { TitleCard, TitleCardProps } from './TitleCard';


export type SubmitHandler<T> = (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<unknown>;

// css and onSubmit are not comptabile with a material-ui card
type TitleCardPropsOverride = Omit<TitleCardProps, 'css' | 'onSubmit'>;

export interface FormCardProps<T> extends TitleCardPropsOverride {
  /**
   * Optional className to forward.
   */
  className?: string;

  /**
   * Initial values in the form.  `T` is the type that represents the shape of the data and values are specific values for each field.
   */
  values: T;

  /**
   * This is a schema specified using yup to validate the data.
   */
  validationSchema?: unknown;

  /**
   * Is there a cancel button.
   */
  showCancel?: boolean;

  /**
   * Special button props to pass to the cancel button.
   */
  cancelButtonProps?: ButtonProps;

  /**
   * Label for the cancel button.
   */
  cancelLabel?: string;

  /**
   * Label for the submit button.
   */
  submitLabel?: string;

  /**
   * Special button props to pass to the submit button.
   */
  submitButtonProps?: ButtonProps;

  /**
   * React elements to place above the submit button.
   */
  beforeSubmitElements?: JSX.Element | JSX.Element[];

  /**
   * React elements to place below the submit button.
   */
  afterSubmitElements?: JSX.Element | JSX.Element[];

  /**
   * Key to subscribe to for state changes and errors.
   */
  queryKey?: string;

  /**
   * Is the form just sitting on the surface?
   */
  hideCard?: boolean;

  /**
   * Handler called when the submit button is pressed.
   */
  onSubmit: SubmitHandler<T>;

  /**
   * Handler called when the cancel button is pressed.
   */
  onCancel?: () => void;

  /**
   * Submit button should only be enabled if the user changes one of the input fields.
   */
  checkDirty?: boolean;
}

const convertElements = (
  otherProps: Record<string, unknown>,
  elements?: JSX.Element | JSX.Element[] | undefined
): JSX.Element | JSX.Element[] | undefined => {
  if (Array.isArray(elements)) {
    elements.map((element) => {
      return cloneElement(element, { ...element.props, ...otherProps });
    });
  } else if (elements) {
    return cloneElement(elements, { ...elements.props, ...otherProps });
  }
  return (<></>);
};


export function FormCard<T extends FormikValues>({
  className,
  values,
  validationSchema,
  submitLabel = 'Submit',
  submitButtonProps,
  beforeSubmitElements,
  afterSubmitElements,
  children,
  cancelButtonProps,
  showCancel,
  hideCard,
  title,
  titleVariant,
  queryKey = 'no_query_key',
  cancelLabel = 'Cancel',
  onSubmit,
  onCancel,
  checkDirty = true,
  ...other
}: FormCardProps<T>): JSX.Element {
  const theme = useTheme();

  const querySelector = useSelector((state: Record<string, QueriesState>) => state.queries[queryKey]);
  const errorsSelector = useSelector((state: Record<string, ErrorsState>) => state.errors[queryKey]);

  const errors = (errorsSelector && errorsSelector.responseBody) || {};
  const queryPending = querySelector && querySelector.isPending;
  const queryStatus = querySelector && querySelector.status || 0;


  const decoratedOnSubmit: SubmitHandler<T> = (submitValues: T, helpers: FormikHelpers<T>) => {
    helpers.setErrors({});
    return onSubmit(submitValues, helpers);
  };

  return (
    <Formik<T>
      initialValues={values}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={decoratedOnSubmit}
    >
      {(props: FormikProps<T>) => {
        const { handleSubmit, resetForm, submitForm, isSubmitting, dirty, isValid } = props;

        if (props.isSubmitting !== queryPending) {
          if (!queryPending && queryStatus >= 400) {
            props.setErrors({});
            let errorList = []
            if (!Array.isArray(errors)) {
              errorList = [errors]
            } else {
              errorList = errors
            }
            errorList.forEach(errorDefinition => {
              Object.entries(errorDefinition).forEach(([key, error]) => {
                // Convert incoming error to a string since it could be a list of errors
                let errorString: string;
                if (Array.isArray(error)) {
                  errorString = error.join('\n')
                } else {
                  errorString = error as string;
                }

                const { value } = props.getFieldMeta(key);

                // If the field coming in has a "value" then it is a field
                // on the form and the error can be set with the usual setFieldError,
                // otherwise treat this as a non field error and display in the
                // general error section at the bottom of the form.
                if (value) {
                  if (key !== 'non_field_errors') {
                    if (props.errors[key] !== errorString) {
                      props.setFieldError(key, errorString);
                    }
                  }
                } else {
                  errors.non_field_errors = errorString;
                }
              });
            })
          }
          props.setSubmitting(queryPending);
        }
        const renderContent = (): JSX.Element => {
          return (
            <form onSubmit={handleSubmit} style={{ width: other.width }}>
              {children}
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box height={theme.spacing(4)} />
                {convertElements({ helpers: props }, beforeSubmitElements)}
                {errors.non_field_errors && (
                  <Box marginBottom={`${theme.spacing(3)}px`}>
                    <Typography color="error" variant="body1">
                      {errors.non_field_errors}
                    </Typography>
                  </Box>
                )}
                {errors.detail && (
                  <Box marginBottom={`${theme.spacing(3)}px`}>
                    <Typography color="error" variant="body1">
                      {errors.detail}
                    </Typography>
                  </Box>
                )}
                <Box width="100%" display="flex" justifyContent="center" flexDirection="row">
                  {showCancel && (
                    <Button
                      style={{ borderRadius: 0 }}
                      size="large"
                      disableElevation
                      onClick={() => {
                        resetForm();
                        if (onCancel) onCancel();
                      }}
                      {...cancelButtonProps}
                    >
                      {cancelLabel}
                    </Button>
                  )}
                  {showCancel && <Box width={theme.spacing(2)} />}
                  <Button
                    variant="contained"
                    style={{ borderRadius: 0 }}
                    size="large"
                    disableElevation
                    color="primary"
                    disabled={isSubmitting || (checkDirty && !dirty) || !isValid}
                    onClick={submitForm}
                    {...submitButtonProps}
                    type="submit"
                  >
                    {submitLabel}
                  </Button>
                </Box>
                {afterSubmitElements && <Box height={theme.spacing(2)} />}
                {convertElements({ helpers: props }, afterSubmitElements)}
                <Box height={theme.spacing(1)} />
                {isSubmitting && <LinearProgress style={{ width: '100%' }} />}
              </Box>
            </form>
          );
        };
        if (hideCard) {
          return (
            <Box className={className} {...other}>
              <Typography variant={titleVariant || 'h5'}>{title}</Typography>
              {renderContent()}
            </Box>
          );
        }
          return (
            <TitleCard
              className={className}
              showFlag={false}
              hoverVariant="none"
              title={title}
              titleVariant={titleVariant}
              {...other}
            >
              {renderContent()}
            </TitleCard>
          );

      }}
    </Formik>
  );
}
