/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestAsync } from 'redux-query';
import { useRequest } from 'redux-query-react';

// Material-UI imports
import { Container, Typography, Box, useTheme, CircularProgress, useMediaQuery } from '@material-ui/core';

// API imports
import { MemberDetails, UserDetails, organizationsRead, Role, OrganizationDetail } from '@wisi-tv/okapi-api';

// Common imports
import { AppState } from '../../../../store';
import { hasAccess } from '../../../../utils';
import { SimpleDialog } from '../../../../components';

// Local imports
import { EditMemberForm, InviteForm, TeamTable } from './components';
import { EditValues, TeamMemberDetails } from './types';
import { doDelete } from './operations';
import { convertRole } from './utils';
import { EditTagForm } from './components/EditTagForm';


export const Team: FC = () => {
  /** **************************************************************************
   *  Initial theme and hook setup
   ************************************************************************** */
  const theme = useTheme();
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();

  /** **************************************************************************
   * Redux query selectors and dispatchers
   ************************************************************************** */
  const userRead = useSelector<AppState, UserDetails>((state: AppState) => state.entities.usersMeRead);
  const selectedOrg = useSelector((state: AppState) => state.system.selectedOrg) || null;
  const orgDetails = useSelector<AppState, OrganizationDetail>((state: AppState) => state.entities.organizationsRead);
  const roles = useSelector<AppState, Role[]>((state: AppState) => state.entities.rolesList);
  const orgReadState = useRequest(
    selectedOrg?.pk ? organizationsRead({ orgId: selectedOrg.pk.toString() }) : undefined
  )[0];
  const { pk, groups } = userRead;
  const { members } = orgDetails || { members: [] };

  /** **************************************************************************
   * Local state
   ************************************************************************** */
  const [memberValues, setInitialMemberValues] = useState<EditValues>({
    tag: '',
  });
  const [selectedRows, setSelectedRows] = useState<MemberDetails[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

  /** **************************************************************************
   * Side effects
   ************************************************************************** */

  /**
   * Handles the case for the selected organization changing
   */
  useEffect(() => {
    if (!selectedOrg?.pk) return;
    dispatch(requestAsync(organizationsRead({ orgId: selectedOrg.pk.toString() }, { force: true })));
  }, [selectedOrg, dispatch]);

  /** **************************************************************************
   * Event handlers
   ************************************************************************** */

  const onInviteMember = useCallback(() => {
    setInviteDialogOpen(true);
  }, []);

  const onDeleteMember = useCallback((event: React.MouseEvent<HTMLButtonElement>, selection: MemberDetails[]) => {
    setSelectedRows(selection);
    setDeleteDialogOpen(true);
  }, []);

  const onEditTags = useCallback((event: React.MouseEvent<HTMLButtonElement>, selection: MemberDetails[]) => {
    const row = selection[0];
    setInitialMemberValues({ tag: row.tag || '', userPk: row.userPk });
    setSelectedRows(selection);
    setTagDialogOpen(true);
  }, []);

  const onEditMember = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, selection: MemberDetails[]) => {
      const row = selection[0];
      let initialRole;
      roles.some(role => {
        if (role.name === row.role) {
          initialRole = role.pk;
          return true;
        }
        return false;
      })

      setInitialMemberValues({
        tag: row.tag || '',
        role: initialRole,
        userPk: row.userPk,
      });
      setSelectedRows(selection);
      setEditDialogOpen(true);
    },
    [roles]
  );

  /** **************************************************************************
   * Local variables - typically derived from state and used in renders
   ************************************************************************** */
  const adminAccess = useMemo(() => hasAccess(groups as Record<string, unknown>, selectedOrg, ['org:admin']), [groups, selectedOrg]);
  const roleMap: {[a: string]: string} = useMemo(
    () => {
      const ret: {[a: string]: string} = {}

      if (roles) {
        roles.forEach( role => {
          ret[role.name] = convertRole(role.name);
        })
      }

      return ret;
    },
    [roles]
  );

  const sortedMembers = useMemo(() => {
    if (members.length === 0) {
      return [];
    }
    return members
      .sort((member1, member2) => {
        if (member1.userPk === pk) return -1;
        if (member2.userPk === pk) return 1;
        if (!member1.name) return 0;
        if (!member2.name) return 0;
        const name1 = member1.name.toUpperCase();
        const name2 = member2.name.toUpperCase();
        if (name1 < name2) {
          return -1;
        } if (name1 > name2) {
          return 1;
        }
        return 0;
      });
  }, [members, pk]);

  /** **************************************************************************
   * Render functions
   ************************************************************************** */

  /**
   * Main Tool
   */
  const renderTool = useMemo(() => {
    return <Container maxWidth="lg">
      <Typography color="primary" variant="h1">
        Team
      </Typography>
      <TeamTable
        onInviteMember={onInviteMember}
        onDeleteMember={onDeleteMember}
        onEditMember={onEditMember}
        onEditTags={onEditTags}
        roleMap={roleMap}
        paging={sortedMembers.length > 100}
        hasOrgAdminAccess={adminAccess}
        loggedInUserPk={pk}
        members={sortedMembers as TeamMemberDetails[]}
        isDesktop={mdMatches}
      />
    </Container>
  }, [adminAccess, sortedMembers, roleMap, pk, onInviteMember, onDeleteMember, onEditMember, onEditTags, mdMatches]);

  /**
   * Render this when there is no organization selected
   */
  const renderNoOrg = (): JSX.Element => {
    return (
      <Container fixed>
        <Box height={theme.spacing(20)} />
        <Typography variant="h1">Please select an org first</Typography>
      </Container>
    );
  };

  /**
   * Render this when we are loading organization details
   */
  const renderLoading = (): JSX.Element => {
    return (
      <Container fixed>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  };

  /** **************************************************************************
   * Main Render
   ************************************************************************** */
  return (
    <>
      {/* Main tool */}
      {orgReadState.isPending ? renderLoading() : (selectedOrg ? renderTool : renderNoOrg())}

      {/* Supplemental Dialogs */}
      <SimpleDialog
        isOpen={deleteDialogOpen}
        setIsOpen={setDeleteDialogOpen}
        showLogo={false}
        title="Confirm removal of members"
        subTitle="Are you sure you want to remove one or more members?"
        showOk
        okLabel="Yes"
        onOk={() => doDelete(dispatch, selectedOrg, selectedRows, userRead.pk)}
        showCancel
        cancelLabel="No"
      />
      <EditMemberForm
        isOpen={editDialogOpen}
        setIsOpen={setEditDialogOpen}
        memberValues={memberValues}
        roles={roles}
        loggedInUserPk={userRead.pk}
        selectedOrg={selectedOrg}
        selectedRows={selectedRows}
        dispatch={dispatch}
      />
      <EditTagForm
        isOpen={tagDialogOpen}
        setIsOpen={setTagDialogOpen}
        tagValues={memberValues}
        loggedInUserPk={userRead.pk}
        selectedOrg={selectedOrg}
        selectedRows={selectedRows}
        dispatch={dispatch}
      />
      <InviteForm
        isOpen={inviteDialogOpen}
        setIsOpen={setInviteDialogOpen}
        roles={roles}
        selectedOrg={selectedOrg}
        dispatch={dispatch}
      />
    </>
  );
};

