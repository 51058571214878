/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import { OrganizationSummary } from '@wisi-tv/okapi-api';

export const hasAccess = (groups: Record<string, unknown>, selectedOrg: OrganizationSummary | null, roles: string[] = []): boolean => {
  const {system, organization} = groups || {system: [''], organization: {pk: '0'}};
  const orgGroups = (organization as Record<string,[string]>)[selectedOrg?.pk || '0'] || [];
  if ((system as [string]).includes('sys:admin')) {
    return true;
  }
  return roles.some(role => {
    return ((system as [string]).includes(role) || orgGroups.includes(role));
  })
};
