/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import superagent, { Response } from 'superagent';
import { httpMethods, NetworkInterface } from 'redux-query';

const createRequest = (
  url: string,
  method: string,
  body: (err: string, res: Response) => void | undefined,
): superagent.SuperAgentRequest => {
  switch (method) {
    case httpMethods.HEAD:
      return superagent.head(url, body);
    case httpMethods.GET:
      return superagent.get(url, body);
    case httpMethods.POST:
      return superagent.post(url, body);
    case httpMethods.PUT:
      return superagent.put(url, body);
    case httpMethods.PATCH:
      return superagent.patch(url, body);
    case httpMethods.DELETE:
      return superagent.delete(url, body);
    default:
      throw new Error(`Unsupported HTTP method: ${method}`);
  }
};

export const superagentNetworkInterface: NetworkInterface = (url, method, { body, headers, credentials } = {}) => {
  const request = createRequest(url, method, body);

  if (headers) {
    request.set(headers);
  }

  if (credentials === 'include') {
    request.withCredentials();
  }

  // SDG - add the following to allow us to receive binary type responses.
  if (headers && headers.Accept && headers.Accept.toLowerCase() === 'application/octet-stream') {
    request.responseType('blob');
  }

  const execute = (
    cb: (
      err: string,
      status: number,
      responseBody: string | undefined,
      responseText: string | undefined,
      responseHeaders: { string: string },
    ) => void,
  ): void =>
    request.end((err, response) => {
      const resStatus = (response && response.status) || 0;
      const resBody = (response && response.body) || undefined;
      const resText = (response && response.text) || undefined;
      const resHeaders = (response && response.header) || undefined;

      cb(err, resStatus, resBody, resText, resHeaders);
    });

  const abort = (): void => request.abort();

  return {
    abort,
    execute,
  };
};
