/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

/**
 * Contains pure helper functions for the tool that
 * could be shared by the tool itself or by the components.
 */

// API imports
import { Role } from '@wisi-tv/okapi-api';

/**
 * Returns a human friendly representation of the internal role name.
 *
 * @param role Internal role name
 */
export const convertRole = (role: string): string => {
  const roleName = role.split(':')[1];
  return roleName.charAt(0).toUpperCase() + roleName.slice(1);
};

/**
 * Lookup the internal role pk used for a org user.
 *
 * @param roles List of internal roles
 */
export const getUserRole = (roles: Role[]): number | null => {
  if (!roles) return null;
  let ret: number | null = null;
  roles.some(role => {
    if ((role.name === 'org:user') && role.pk) {
      ret = role.pk;
      return true;
    }
    return false;
  })
  return ret;
};
