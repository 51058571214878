/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

/**
 * This file is meant to hold all the write operations that get performed
 * by the tool.
 */

// Third party imports
import { mutateAsync, requestAsync, ReduxQueryDispatch } from 'redux-query';

// API imports
import {
  MemberDetails,
  OrganizationSummary,
  organizationsMembersUpdate,
  organizationsRead,
  organizationsInvitationsCreate,
  organizationsMembersDelete
} from '@wisi-tv/okapi-api';

// Common imports
import { EditValues, InviteValues, TagsValues } from './types';

/**
 * Sends a delete request to the backend to delete one or more users.
 *
 * @param dispatch ReduxQuery dispatcher that is tied to the component
 * @param selectedOrg Current organization that is being operated on
 * @param selectedRows List of rows being deleted
 * @param loggedInUserPk Identifies the authenticated user
 */
export const doDelete = async (
  dispatch: ReduxQueryDispatch<unknown>,
  selectedOrg: OrganizationSummary | null,
  selectedRows: MemberDetails[],
  loggedInUserPk?: number
): Promise<void> => {
  if (!selectedOrg?.pk) return;
  if (loggedInUserPk === undefined) return;

  await Promise.all(selectedRows.map(async (pendingMemberToDelete) => {
    if (!pendingMemberToDelete?.pk) return;
    if (!selectedOrg.pk) return;
    if (pendingMemberToDelete.userPk === loggedInUserPk) return; // Skip delete of the current user

    await dispatch(
      mutateAsync(
        organizationsMembersDelete({
          parentLookupOrgId: selectedOrg.pk.toString(),
          id: pendingMemberToDelete.pk.toString(),
        })
      )
    );
  }))

  // Users are deleted - now refresh users
  dispatch(requestAsync(organizationsRead({ orgId: selectedOrg.pk.toString() }, { force: true })));
};

/**
 * Sends an edit member request to just update the tag for one or more members.
 *
 * @param dispatch ReduxQuery dispatcher that is tied to the component
 * @param selectedOrg Current organization that is being operated on
 * @param selectedRows List of rows being edited
 * @param values Contains the values from the form - in this case, the tag to change to
 */
export const doEditTags = async (
  dispatch: ReduxQueryDispatch<unknown>,
  selectedOrg: OrganizationSummary | null,
  selectedRows: MemberDetails[],
  values: TagsValues
): Promise<void> => {
  if (!selectedOrg?.pk) return;

  await Promise.all(selectedRows.map(async (pendingMemberToUpdate) => {
    if (!pendingMemberToUpdate?.pk) return;
    if (!selectedOrg.pk) return;

    await dispatch(
      mutateAsync(
        organizationsMembersUpdate(
          {
            parentLookupOrgId: selectedOrg.pk.toString(),
            id: pendingMemberToUpdate.pk.toFixed(0),
            data: {
              tag: values.tag,
            },
          },
          {
            queryKey: 'tagUpdate',
          }
        )
      )
    );
  }));

  // Tags are edited - now refresh users
  dispatch(requestAsync(organizationsRead({ orgId: selectedOrg.pk.toString() }, { force: true })));
};

/**
 * Sends an edit member request to update the tag and role for one or more members.
 *
 * @param dispatch ReduxQuery dispatcher that is tied to the component
 * @param selectedOrg Current organization that is being operated on
 * @param selectedRows List of rows being edited
 * @param values Contains the values from the form - in this case, the tag and role to change to
 * @param loggedInUserPk Identifies the authenticated user
 */
export const doEditMember = async (
  dispatch: ReduxQueryDispatch<unknown>,
  selectedOrg: OrganizationSummary | null,
  selectedRows: MemberDetails[],
  values: EditValues,
  loggedInUserPk?: number
): Promise<void> => {
  if (!selectedOrg?.pk) return;
  if (loggedInUserPk === undefined) return;

  await Promise.all(selectedRows.map(async (pendingMemberToUpdate) => {
    if (!pendingMemberToUpdate?.pk) return;
    if (!selectedOrg.pk) return;
    if (!values.role) return;
    await dispatch(
      mutateAsync(
        organizationsMembersUpdate(
          {
            parentLookupOrgId: selectedOrg.pk.toString(),
            id: pendingMemberToUpdate.pk.toFixed(0),
            data: {
              tag: values.tag,
              roles: pendingMemberToUpdate.userPk === loggedInUserPk ? undefined : [values.role.toString()],
            },
          },
          {
            queryKey: 'memberUpdate',
          }
        )
      )
    );
  }));

  // Member is edited - now refresh users
  dispatch(requestAsync(organizationsRead({ orgId: selectedOrg.pk.toString() }, { force: true })));
};

/**
 * Sends an invite member request.
 *
 * @param dispatch ReduxQuery dispatcher that is tied to the component
 * @param selectedOrg Current organization that is being operated on
 * @param values Contains the values from the invite form
 */
export const doInvite = async (
  dispatch: ReduxQueryDispatch<unknown>,
  selectedOrg: OrganizationSummary | null,
  values: InviteValues
): Promise<void> => {
  if (!selectedOrg?.pk) return;

  const name =
    values.lastName && values.lastName.length > 0 ? `${values.firstName} ${values.lastName}` : `${values.firstName}`;

  if (values.role !== null) {
    await dispatch(
      mutateAsync(
        organizationsInvitationsCreate(
          {
            parentLookupOrgId: selectedOrg.pk.toString(),
            data: {
              name,
              email: values.email,
              role: values.role,
            },
          },
          {
            queryKey: 'sendInvite',
          }
        )
      )
    );
  }
  // Member is invited - now refresh users
  dispatch(requestAsync(organizationsRead({ orgId: selectedOrg.pk.toString() }, { force: true })));
};
