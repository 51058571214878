/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Add = createSvgIcon(
  <svg width="27px" height="27px" viewBox="0 0 30 30">
    <g id="WISI-Cloud" stroke="none">
      <g id="Desktop-HD" transform="translate(-1251.000000, -181.000000)">
        <g id="add" transform="translate(1251.000000, 181.000000)">
          <path
            d="M13.160448,0 C5.90369472,0 0,5.90369472 0,13.160448 C0,20.4172013 5.90369472,26.320896 13.160448,26.320896 C20.4172013,26.320896 26.320896,20.4172013 26.320896,13.160448 C26.320896,5.90369472 20.4172013,0 13.160448,0 Z M13.160448,24.440854 C6.94023422,24.440854 1.88004197,19.3806618 1.88004197,13.160448 C1.88004197,6.94023422 6.94023422,1.88004197 13.160448,1.88004197 C19.3806618,1.88004197 24.440854,6.94023422 24.440854,13.160448 C24.440854,19.3806618 19.3806618,24.440854 13.160448,24.440854 Z"
            id="Shape"
           />
          <path
            d="M13.160448,6.89355576 C12.6412272,6.89355576 12.2204013,7.31438165 12.2204013,7.83360245 L12.2204013,18.4872936 C12.2204013,19.0065144 12.6412272,19.4273402 13.160448,19.4273402 C13.6796688,19.4273402 14.1004947,19.0065144 14.1004947,18.4872936 L14.1004947,7.83360245 C14.1004947,7.31438165 13.6796688,6.89355576 13.160448,6.89355576 Z"
            id="Path"
           />
          <path
            d="M18.4872936,12.2204013 L7.83360245,12.2204013 C7.31438165,12.2204013 6.89355576,12.6412272 6.89355576,13.160448 C6.89355576,13.6796688 7.31438165,14.1004947 7.83360245,14.1004947 L18.4872936,14.1004947 C19.0065144,14.1004947 19.4273402,13.6796688 19.4273402,13.160448 C19.4273402,12.6412272 19.0065144,12.2204013 18.4872936,12.2204013 Z"
            id="Path"
           />
        </g>
      </g>
    </g>
  </svg>,
  'Add'
);
