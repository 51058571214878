/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC, useEffect, useState } from 'react';

// Material-UI imports

// Other Third party imports
import { Field } from 'formik';
import { mutateAsync, MutateAsyncAction, QueryResponse } from 'redux-query';
import * as yup from 'yup';

// API imports
import { ErpItemDefinition, assemblyitemRead, assemblyitemCreate, ErpWorkOrderFromJSON, BomPartItem, bomRead, BomRevision } from '@wisi-tv/okapi-api';

// Common imports
import { Backdrop, Box, CircularProgress, LinearProgress, List, ListItem, MenuItem, Select, Typography, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { DialogFormCard, SimpleDialog, TextField } from '../../../../../components';
import { AppState } from '../../../../../store';

// Local imports

export interface BomInputFormProps {
  /**
   * useState setter that is used to set the open/close state of the dialog.
   */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * useState getter that is used to indicate the current open/close state of the dialog.
   */
  isOpen: boolean;

  /**
   * This is the required BOM to fill out.
   */
  selectedErpItem?: ErpItemDefinition;
}

/**
 * Form responsible for filling in BOM serial numbers.
 */
export const BomInputForm: FC<BomInputFormProps> = (props: BomInputFormProps) => {
  const bomPartFields: React.ReactNode[] = [];
  const validationObjects: Record<string, yup.StringSchema<string, unknown>> = {};
  const initialValues: Record<string, string> = {};

  const assemblyItem = useSelector<AppState, ErpItemDefinition>((state: AppState) => state.entities.assemblyitemRead);
  const bomRevisionDetails = useSelector<AppState, BomRevision | undefined>((state: AppState) => state.entities.bomRead)

  const requestState = useRequest(props.selectedErpItem ? assemblyitemRead({id: props.selectedErpItem?.internalId}, {force: true}) : null)[0];
  const dispatch = useDispatch();
  const theme = useTheme();

  const [outputSerialNumber, setOutputSerialNumber] = useState('');
  const [finishedDialogOpen, setFinishedDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [selectedBomRevision, setSelectedBomRevision] = useState<string | null>(null);

  useEffect(() => {
    if (assemblyItem && assemblyItem.bomRevisions.length > 0) {
      setSelectedBomRevision(assemblyItem.bomRevisions[0].internalId);
    }
  }, [assemblyItem]);

  const bomRequestState = useRequest(selectedBomRevision ? bomRead({id: selectedBomRevision}, {force: true}) : null)[0];

  let setInputRef = false;
  let nonSerializedParts: string[] = [];
  let hasSerializedPart = false;

  if (assemblyItem && assemblyItem.bomRevisions) {
    bomRevisionDetails?.parts
      .filter((part) => part.isSerialized)
      .forEach((part) => {
        hasSerializedPart = true;
        if (part.quantity && part.quantity > 1) {
          for (let i = 0; i < part.quantity; i += 1) {
            const uniqueName = `${part.internalId}-${part.stockPartId}-${bomPartFields.length + 1}`;

            bomPartFields.push(
              <Field
                autoFocus={!setInputRef}
                key={uniqueName}
                component={TextField}
                fullWidth
                label={`${part.displayName} #${i + 1} - Serial Number`}
                name={uniqueName}
              />,
            );
            validationObjects[uniqueName] = yup
              .string()
              .required(`Serial number for ${part.displayName} #${i + 1} is required`);
            initialValues[uniqueName] = '';
            setInputRef = true;
          }
        } else {
          const uniqueName = `${part.internalId}-${part.stockPartId}-${bomPartFields.length + 1}`;

          bomPartFields.push(
            <Field
              autoFocus={!setInputRef}
              key={uniqueName}
              component={TextField}
              fullWidth
              label={`${part.displayName} - Serial Number`}
              name={uniqueName}
            />,
          );
          validationObjects[uniqueName] = yup
            .string()
            .required(`Serial number for ${part.displayName} is required`);
          initialValues[uniqueName] = '';
          setInputRef = true;
        }
      });
  }

  if (bomRevisionDetails) {
    nonSerializedParts = bomRevisionDetails.parts
      .filter((part) => !part.isSerialized)
      .map((part) => {
        return part.displayName || part.internalId;
      });
  }

  const validationSchema = yup.object().shape(validationObjects);

  const renderProgress = (): JSX.Element => {
    return (
      <>
        <Box height={`${theme.spacing(1)}px`} />
        <Typography variant="body1">Loading details for BOM...</Typography>
        <Box height={`${theme.spacing(3)}px`} />
        <LinearProgress/>
      </>
    )
  }

  const renderFormFields = (): JSX.Element => {
    return (
      <>
        <Box height={`${theme.spacing(1)}px`} />

        <Typography variant="body1">{`List of parts required to build ${assemblyItem && assemblyItem.displayName}`}</Typography>

        {nonSerializedParts.length > 0 && (
          <>
            <Box height={`${theme.spacing(1)}px`} />
            <Typography variant="body2">Parts that do not require a serial number</Typography>
            <List>
              {nonSerializedParts.map(partName => {
                return <ListItem key={partName}>{partName}</ListItem>
              })}
            </List>
          </>
        )}

        {bomPartFields.length > 0 && (
          <>
            <Typography variant="body2">Enter the serial numbers for all of the serialized parts in the assembly</Typography>
            <Box height={`${theme.spacing(1)}px`} />
          </>
        )}
        {bomPartFields}
      </>
    )
  }

  return (
    <>
      <DialogFormCard<Record<string, string>>
        queryKey="erpCreate"
        setIsOpen={props.setIsOpen}
        isOpen={assemblyItem && assemblyItem.bomRevisions && requestState.isFinished && props.isOpen || false}
        values={initialValues}
        checkDirty={hasSerializedPart}
        validationSchema={validationSchema}

        doSubmit={async (values: Record<string, string>) => {
          if (!bomRevisionDetails) return;

          const inputParts: BomPartItem[] = [];

          bomRevisionDetails.parts
            .filter((part) => !part.isSerialized)
            .forEach((part) => {
              for (let i = 0; i  < part.quantity; i+=1) {
                inputParts.push({
                  stockPartId: part.stockPartId,
                  serialNumber: ''
                });
              }
            });

          Object.keys(values).forEach(key => {
            const tokens = key.split('-');
            inputParts.push({
              stockPartId: Number.parseInt(tokens[1],10),
              serialNumber: values[key]
            })
          })

          bomRevisionDetails.outputStockPartId = assemblyItem.outputStockPartId;

          const createdItem = await dispatch<MutateAsyncAction>(mutateAsync(assemblyitemCreate({
            data: {
              identifier: `Autogenerated build assembly (NetSuite ID:${bomRevisionDetails.internalId})`,
              bom: bomRevisionDetails,
              pickItems: inputParts,
              quantity: 1
            },
          }, {
            queryKey: "erpCreate"
          })));

          if (((createdItem as unknown) as QueryResponse).status < 300) {
             const workOrder = ErpWorkOrderFromJSON(createdItem.body);
             if (workOrder.output) {
               setOutputSerialNumber(workOrder.output[0].serialNumber || ''); // Only take first output for now - don't support quantity > 1
               setFinishedDialogOpen(true);
             } else {
               setErrorDialogOpen(true);
             }
          }
        }}
        submitLabel="Build"
        title={`BOM for ${assemblyItem && assemblyItem.displayName}`}
        subTitle="Select a BOM revision to build"
      >
        <Select fullWidth value={selectedBomRevision} onChange={(event: React.ChangeEvent<{ value: unknown }>) => setSelectedBomRevision(event.target.value as string)}>
          {assemblyItem && assemblyItem.bomRevisions &&
            assemblyItem.bomRevisions.map((bomRevision) => (
              <MenuItem key={bomRevision.internalId} value={bomRevision.internalId}>
                {bomRevision.displayName}
              </MenuItem>
            ))}
        </Select>

        {bomRequestState.isFinished && renderFormFields()}
        {!bomRequestState.isFinished && renderProgress()}
      </DialogFormCard>
      <Backdrop style={{zIndex: 10000}} open={requestState.isPending}>
        <CircularProgress/>
      </Backdrop>
      <SimpleDialog title="Item has been built" subTitle={`Item built with serial number: ${outputSerialNumber}`}  setIsOpen={setFinishedDialogOpen} isOpen={finishedDialogOpen}/>
      <SimpleDialog title="Failed to build item" subTitle="Result came back as success from server but no output provided"  setIsOpen={setErrorDialogOpen} isOpen={errorDialogOpen}/>
    </>
  );
};
