/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { FC } from 'react';
import {
  CardHeader,
  IconButton,
  Typography,
  CardMedia,
  CardActions,
  Box,
  CardContent,
  SvgIcon,
  useTheme
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, CardProps } from './Card';

export interface TitleCardProps extends CardProps {
  /**
   * This is the title that goes in the card header.
   */
  title?: string;

  /**
   * This is the Material typography variant for the title text.
   */
  titleVariant?: 'h3' | 'h4' | 'h5' | 'h6';

  /**
   * The subtitle is optional
   */
  subTitle?: string;

  /**
   * Optional navigation linkTo to be put in the header title.
   */
  linkTo?: string;

  /**
   * Optional link to picture to display below title.
   */
  picture?: string;

  /**
   * This is for optional actions to be placed at the start of the footer.
   */
  startActions?: JSX.Element | JSX.Element[];

  /**
   * This is for optional actions to be placed at the end of the footer.
   */
  endActions?: JSX.Element | JSX.Element[];

  /**
   * Do we want a flag below the header bar?
   */
  showFlag?: boolean;
}

export const TitleCard: FC<TitleCardProps> = ({
  linkTo,
  picture,
  hoverVariant,
  titleVariant,
  title,
  subTitle,
  endActions,
  startActions,
  showFlag,
  children,
  ...otherProps
}: TitleCardProps) => {
  const history = useHistory();
  const theme = useTheme();
  return (
    <Card hoverVariant={hoverVariant} {...otherProps}>
      {title && (
        <Box display="flex" flexDirection="column">
          <CardHeader
            disableTypography
            action={
              linkTo && (
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => {
                    if (history) history.push(linkTo);
                  }}
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </IconButton>
              )
            }
            title={<Typography variant={titleVariant}>{title}</Typography>}
            subheader={subTitle && <Typography variant="caption">{subTitle}</Typography>}
          />
          {showFlag && (
            <Box left="40%">
              <SvgIcon htmlColor={theme.palette.primary.main} viewBox="0 0 28 28">
                <polygon points="4,0 14,12 24,0 4,0" />
              </SvgIcon>
            </Box>
          )}
        </Box>
      )}
      {picture && <CardMedia component="img" image={picture} height="150" title={title} />}
      {startActions || endActions ? (
        <CardActions>
          {startActions}
          <Box flexGrow={1} />
          {endActions}
        </CardActions>
      ) : undefined}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

TitleCard.defaultProps = {
  showFlag: true,
} as Partial<TitleCardProps>;
