/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { FC } from 'react';
import {
  Dialog,
  makeStyles,
  Theme,
  createStyles,
  DialogProps,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Button
} from '@material-ui/core';
import { Logo } from '../logo';

export interface SimpleDialogProps extends Omit<DialogProps, 'open'> {
  /**
   * Result from a useState hook indicating if the dialog is open.
   */
  isOpen: boolean;

  /**
   * Result from a useState hook to control the open/close state of the dialog.
   */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Is the logo to be shown?
   */
  showLogo?: boolean;

  /**
   * Main title.
   */
  title?: string;

  /**
   * Main subtitle.
   */
  subTitle?: string;

  /**
   * Optional label for the ok button.
   */
  okLabel?: string;

  /**
   * Is ok shown.
   */
  showOk?: boolean;

  /**
   * Handler when the ok button is clicked.
   */
  onOk?: () => void;

  /**
   * Is cancel shown.
   */
  showCancel?: boolean;

  /**
   * Optional cancel label for the cancel button.
   */
  cancelLabel?: string;

  /**
   * Handler when the cancel button is clicked.
   */
  onCancel?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        boxShadow: 'none',
      },
    },
    container: (matches: boolean) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: matches ? '500px' : '300px',
      padding: matches ? theme.spacing(4) : theme.spacing(2),
    }),
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      marginTop: theme.spacing(2),
    },
    logo: {
      marginBottom: theme.spacing(4),
    },
  })
);

export const SimpleDialog: FC<SimpleDialogProps> = ({
  setIsOpen,
  isOpen,
  title,
  subTitle,
  showLogo = true,
  showOk = true,
  okLabel = 'Ok',
  onOk,
  showCancel = false,
  cancelLabel = 'Cancel',
  onCancel,
  children,
}: SimpleDialogProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles(matches);

  return (
    <Dialog className={classes.dialog} open={isOpen}>
      <div className={classes.container}>
        {showLogo && <Logo variant="small" className={classes.logo} />}
        {title && <Typography variant="h3">{title}</Typography>}
        {subTitle && <Box height={`${theme.spacing(1)}px`} />}
        {subTitle && <Typography variant="body1">{subTitle}</Typography>}
        {children}
        {(showOk || showCancel) && (
          <div className={classes.buttonContainer}>
            {showCancel && (
              <Button
                style={{ borderRadius: 0 }}
                size="large"
                disableElevation
                onClick={() => {
                  setIsOpen(false);
                  if (onCancel) onCancel();
                }}
              >
                {cancelLabel}
              </Button>
            )}
            {showCancel && <Box width={theme.spacing(2)} />}
            <Button
              variant="contained"
              style={{ borderRadius: 0 }}
              size="large"
              disableElevation
              color="primary"
              onClick={() => {
                setIsOpen(false);
                if (onOk) onOk();
              }}
            >
              {okLabel}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};
