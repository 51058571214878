/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';

import clsx from 'clsx';
import BaseMaterialTable, { MaterialTableProps as BaseMaterialTableProps, MTableToolbar } from 'material-table';

import {
  ArrowDownward,
  Search,
  Clear,
  FilterList,
  ViewColumn,
  Edit,
  Check,
  Remove,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft
} from '@material-ui/icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, makeStyles, createStyles, Theme, useTheme, Typography } from '@material-ui/core';
import BaseMTableBodyRow from './m-table-body-row';
import BaseMTableHeader from './m-table-header';
import BaseMFilterRow from './m-table-filter-row';
import BaseMTableCell from './m-table-cell';


/**
 * For typing purposes we need to indicate that a dataManager exists with a data array.
 *
 * Material-Table doesn't export the ToolbarProps out of their javascript so we
 * have to type it here.
 */
interface CustomToolbarProps {
  dataManager: {data: unknown[]}
}

/**
 * Customized toolbar that shows a count of all the records pre-filters.
 */
const CustomToolbar: React.FC<CustomToolbarProps> = (props: CustomToolbarProps) => {
  return (
    <div className="customtoolbar">
      <MTableToolbar {...props}/>
      <Typography variant="caption">{`Total: ${props.dataManager.data.length}`}</Typography>
    </div>
  );
}

export interface MaterialTableProps<RowData extends Record<string,unknown>> extends BaseMaterialTableProps<RowData> {
  /**
   * Override for styles
   */
  className?: string;

  /**
   * Where is the search input box
   */
  searchFieldAlignment?: 'left' | 'right' | undefined;

  /**
   * What is the minimum body height for the table
   */
  minBodyHeight?: string | number | undefined;

  /**
   * Should the selectAll records button be shown
   */
  showSelectAllCheckbox?: boolean | undefined;

  /**
   * Should the button to manage the columns be shown
   */
  showColumnsButton?: boolean | undefined;

  /**
   * Can the individual rows be selected
   */
  selection?: boolean | undefined;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiPaper-root': {
        boxShadow: 'none',
        // There is an annoying horizontal scrollbar that appears
        // when using grouping.  This makes sure it is not there.
        '& >:not(.MuiToolbar-root)': {
          '& >div': {
            '& >div': {
              overflowX: 'hidden',
            },
          },
        },
      },
      '& .customtoolbar': {
        // Set the overflow to hidden because when adding the total under
        // the search box we get a slim vertical scrollbar.  This hides it.
        '& .MuiFormControl-root': {
          overflow: "hidden"
        },
      },
      '& .MuiToolbar-gutters': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        '& .MuiOutlinedInput-input': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
      },
    },
  });
});

export const MaterialTable = <T extends Record<string,unknown>>(outerProps: MaterialTableProps<T>): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();

  // material-table uses react-beautiful-dnd and this injects styls via scripts.  We can
  // use the cspNonce option in material-table to pass the current nonce the server has
  // provided us.
  const cspNonceMeta = document.querySelector<HTMLMetaElement>('meta[property="csp-nonce"');
  const cspNonceContent = cspNonceMeta ? cspNonceMeta.content : '';

  return (
    <div className={clsx(classes.root, outerProps.className)}>
      <BaseMaterialTable
        {...outerProps}
        icons={{
          SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
          Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
          Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
          Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
          ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
          ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
          FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
          LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
          NextPage: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
          PreviousPage: React.forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
          DetailPanel: React.forwardRef((props, ref) => (
            <Icon ref={ref} {...props} component="svg">
              <FontAwesomeIcon icon={faAngleRight} />
            </Icon>
          )),
          Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
          Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
          ...outerProps.icons /* allows for overrides on icons */,
        }}
        options={{
          headerStyle: {
            color: theme.palette.primary.main,
            fontSize: '18px',
          },
          searchFieldAlignment: outerProps.searchFieldAlignment,
          minBodyHeight: outerProps.minBodyHeight,
          showSelectAllCheckbox: outerProps.showSelectAllCheckbox,
          selection: outerProps.selection,
          columnsButton: outerProps.showColumnsButton,
          showTitle: false,
          paging: false,
          detailPanelType: 'single',
          filtering: false,
          cspNonce: cspNonceContent,
          ...outerProps.options /* allows for overrides on options */,
        }}
        components={{
          Row: BaseMTableBodyRow,
          Header: BaseMTableHeader,
          FilterRow: BaseMFilterRow,
          Cell: BaseMTableCell,
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

MaterialTable.defaultProps = {
  searchFieldAlignment: 'right',
  minBodyHeight: '300px',
  showSelectAllCheckbox: false,
  selection: true,
  showColumnsButton: false,
} as Partial<MaterialTableProps<Record<string, unknown>>>;
