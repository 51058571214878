/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Manual = createSvgIcon(
  <g id="WISI-Cloud" stroke="none">
    <g id="manual">
      <path
        d="M12.9136402,6.83193139 L3.80439836,6.83193139 C3.38514194,6.83193139 3.04528004,7.17179329 3.04528004,7.59104971 C3.04528004,8.01030612 3.38514194,8.35016802 3.80439836,8.35016802 L12.9136402,8.35016802 C13.3328966,8.35016802 13.6727585,8.01030612 13.6727585,7.59104971 C13.6727585,7.17179329 13.3328966,6.83193139 12.9136402,6.83193139 Z"
        id="Path"
       />
      <path
        d="M12.9136402,9.86836017 L3.80439836,9.86836017 C3.38514194,9.86836017 3.04528004,10.2082221 3.04528004,10.6274785 C3.04528004,11.0467349 3.38518642,11.3865523 3.80439836,11.3865523 L12.9136402,11.3865523 C13.3328966,11.3865523 13.6727585,11.0466904 13.6727585,10.627434 C13.6727585,10.2081776 13.3328966,9.86836017 12.9136402,9.86836017 Z"
        id="Path"
       />
      <path
        d="M12.9136402,12.9047445 L3.80439836,12.9047445 C3.38514194,12.9047445 3.04528004,13.2446064 3.04528004,13.6638628 C3.04528004,14.0831192 3.38514194,14.4229811 3.80439836,14.4229811 L12.9136402,14.4229811 C13.3328966,14.4229811 13.6727585,14.0831192 13.6727585,13.6638628 C13.6727585,13.2446064 13.3328966,12.9047445 12.9136402,12.9047445 Z"
        id="Path"
       />
      <path
        d="M12.519936,1.02932354e-05 C12.6950916,-0.000967608523 12.8634517,0.0677684875 12.9877862,0.191114567 L12.9877862,0.191114567 L16.9414502,4.14477857 C17.0647963,4.26915425 17.1335736,4.43747326 17.1325554,4.61262881 L17.1325554,4.61262881 L17.1325554,19.1093968 C17.1325554,20.2011846 16.2474998,21.0862288 15.155712,21.0862288 L15.155712,21.0862288 L1.976832,21.0862288 C0.88504416,21.0862288 0,20.2011846 0,19.1093968 L0,19.1093968 L0,1.97685281 C0,0.885064967 0.88504416,1.02932354e-05 1.976832,1.02932354e-05 L1.976832,1.02932354e-05 Z M11.860992,1.31790881 L1.976832,1.31790881 C1.61288899,1.31790881 1.317888,1.6129098 1.317888,1.97685281 L1.317888,1.97685281 L1.317888,19.1093968 C1.317888,19.4733398 1.61288899,19.7683408 1.976832,19.7683408 L1.976832,19.7683408 L15.155712,19.7683408 C15.519655,19.7683408 15.814656,19.4733398 15.814656,19.1093968 L15.814656,19.1093968 L15.814656,5.27157281 L13.17888,5.27157281 C12.4510352,5.27157281 11.860992,4.68152964 11.860992,3.95368481 L11.860992,3.95368481 L11.860992,1.31790881 Z M12.9136402,15.9411732 C13.3328966,15.9411732 13.6727585,16.2810351 13.6727585,16.7002916 C13.6727585,17.119548 13.3328966,17.4594099 12.9136402,17.4594099 L12.9136402,17.4594099 L3.80439836,17.4594099 C3.38514194,17.4594099 3.04528004,17.119548 3.04528004,16.7002916 C3.04528004,16.2810351 3.38514194,15.9411732 3.80439836,15.9411732 L3.80439836,15.9411732 Z"
        id="Path-2"
       />
    </g>
  </g>,
  'Manual'
);
