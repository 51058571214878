/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import path from 'path';

// Material-UI imports
import { CircularProgress, Link, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';

// API imports
import {
  ContentEntrySummary,
  organizationsDevicesLicense,
  OrganizationSummary
} from '@wisi-tv/okapi-api';

// Common imports
import { ReduxQueryDispatch, requestAsync } from 'redux-query';
import { CreateTicket, Framework, Licenses, Manual } from '../../../../../components/icons';

// Local imports
import { DeviceWithSearchableModules } from '../types';
import { SimpleDialog } from '../../../../../components';


export interface MoreActionsMenuProps {
  /**
   * Anchor point for this menu - null will hide menu.
   */
  anchorEl: HTMLElement | null;

  /**
   * State setter that is used for the menu to close itself.
   */
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;

  /**
   * What is the current organization selected.
   */
  selectedOrg: OrganizationSummary | null;

  /**
   * Logged in user's name
   */
  userName?: string;

  /**
   * Does the logged in user have access to download the license.
   */
  hasAccessToDownloadLicense?: boolean;

  /**
   * Device that the more actions is being clicked on.
   */
  selectedDevice: DeviceWithSearchableModules | null;

  /**
   * List of content entries from the catalog
   */
  entries: ContentEntrySummary[];

  /**
   * Optional redux dispatcher
   */
  dispatch?: ReduxQueryDispatch<unknown>;
}


/**
 * Menu displayed when the 'more action' button is clicked for a device.
 */
export const MoreActionsMenu: FC<MoreActionsMenuProps> = (props: MoreActionsMenuProps) => {
  const { entries, selectedDevice } = props;
  const [downloading, setDownloading] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * Render firmware download menu items - only update if the list of
   * content entries changes or if the selected device is changed.
   */
  const renderDownloadFirmware = useMemo(() => {
    let ret = null;
    if (selectedDevice && entries) {
      entries.forEach(entry => {
        if (entry.category !== 1) return;
        if (entry.productList && entry.productList?.indexOf(selectedDevice.partNumber) > -1) {
          entry.versions.forEach(version => {
            if (version.isLatest) {
              ret = (
                <MenuItem>
                  <ListItemIcon>
                    <Framework color="primary" />
                  </ListItemIcon>
                  <Link href={version.url}>
                    <ListItemText primary={entry.name} />
                  </Link>
                </MenuItem>
              );
            }
          })
        }
      })
    }
    return ret;
  }, [entries, selectedDevice]);

  /**
   * Render manual download menu items - only update if the list of
   * content entries changes or if the selected device is changed.
   */
  const renderDownloadManual = useMemo(() => {
    const ret: JSX.Element[] = [];
    let index = 0;

    if (selectedDevice && entries) {
      entries.forEach(entry => {
        if (entry.category !== 2) return;
        if (entry.productList && entry.productList?.indexOf(selectedDevice.partNumber) > -1) {
          entry.versions.forEach(version => {
            if (version.isLatest) {
              ret.push(
                <MenuItem key={`manual-${index}`}>
                  <ListItemIcon>
                    <Manual color="primary" />
                  </ListItemIcon>
                  <Link href={version.url} target="_blank">
                    <ListItemText primary={entry.name} />
                  </Link>
                </MenuItem>
              );
              index += 1;
            }
          });
        }
      });
    }
    return ret;
  }, [entries, selectedDevice]);

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={() => props.setAnchorEl(null)}
      >
        <MenuItem>
          <ListItemIcon>
            <CreateTicket color="primary" />
          </ListItemIcon>
          <Link
            href={`mailto:support@wisi.tv?subject=[IncaSP] S/N:${selectedDevice?.serialNumber} ${props.selectedOrg?.name}&body=## Generated from the Inca Support Portal for part number ${selectedDevice?.partNumber} ##%0d%0a%0d%0aPlease describe your request:%0d%0a%0d%0aIf you are reporting an error please include:%0d%0a%0d%0a1. What action was being performed?%0d%0a%0d%0a2. Can the error be reproduced? If yes, please describe.%0d%0a%0d%0a3. If available, please include a screenshot of the result.`}
            variant="inherit"
          >
            <ListItemText primary="Create Ticket" />
          </Link>
        </MenuItem>
        {props.hasAccessToDownloadLicense && (
          <MenuItem
            button
            disabled={downloading}
            onClick={() => {
              if (
                props.dispatch &&
                props.selectedOrg &&
                props.selectedOrg.pk &&
                props.selectedDevice &&
                props.selectedDevice.serialNumber
              ) {
                setDownloading(true);
                return props
                  .dispatch(
                    requestAsync(
                      organizationsDevicesLicense(
                        {
                          parentLookupOrgId: props.selectedOrg?.pk?.toString(),
                          id: props.selectedDevice?.serialNumber?.toString(),
                        },
                        {
                          force: true,
                          options: {
                            headers: { Accept: 'application/octet-stream' },
                          },
                        }
                      )
                    )
                  )
                  .then((value) => {
                    if (value.status === 200) {
                      if (value.body) {
                        const { headers } = value;

                        if (headers) {
                          const disposition = headers['content-disposition'];

                          const match = disposition ? disposition.match(/filename="(.*)"/) : null;

                          if (match) {
                            const filename = path.basename(match[1]);

                            saveAs(value.body, filename);
                          }
                        }
                      }
                    } else {
                      const reader = new FileReader();

                      // This fires after the blob has been read/loaded.
                      reader.addEventListener('loadend', () => {
                        if (typeof reader.result === 'string') {
                          let result;
                          try {
                            result = JSON.parse(reader.result);
                          } catch {
                            // Result should always be a json parseable string from Django so we should
                            // never get here.
                            console.error(`Unexpected result - ${reader.result}`)
                          }

                          if (result) {
                            // If the result is a ValidationError (i.e. 4xx) then the server returns a
                            // list of errors.  If it is a 5xx than an object is returned with detail set.
                            if (Array.isArray(result)) {
                              setErrorMessage(result[0]);
                            } else {
                              setErrorMessage(result.detail);
                            }
                          }
                        }
                        setErrorDialogOpen(true);
                      });

                      // Start reading the blob as text.
                      reader.readAsText(value.body);
                    }
                    return setDownloading(false);
                  });
              }
              return null;
            }}
          >
            <ListItemIcon>
              {downloading ? <CircularProgress size="20px" thickness={2} /> : <Licenses color="primary" />}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                color: 'primary',
              }}
              primary={downloading ? 'Downloading License...' : 'Download License'}
            />
          </MenuItem>
        )}
        {renderDownloadFirmware}
        {renderDownloadManual}
      </Menu>
      <SimpleDialog
        isOpen={errorDialogOpen}
        setIsOpen={setErrorDialogOpen}
        showLogo={false}
        title="Error: Downloading License"
        subTitle={errorMessage}
        showOk
        okLabel="Create Support Ticket"
        onOk={() => {
          return document.querySelector<HTMLLinkElement>('#mymailto')?.click();
        }}
        showCancel
        cancelLabel="Ok"
      />
      <Link
        href={`mailto:support@wisi.tv?subject=[IncaSP] ${props.selectedOrg?.name}: Error downloading license&body=## Generated from the Inca Support Portal for serial number(s): ${selectedDevice?.serialNumber} ##%0d%0a%0d%0aHello Support,%0d%0a%0d%0aThe Inca Support Portal had an error when clicking download license for equipment ${selectedDevice?.partNumber}.%0d%0a%0d%0aCan you please investigate and contact me with the results and a way to obtain my license file?%0d%0a%0d%0aThank you,%0d%0a%0d%0a${props.userName}`}
        id="mymailto"
        style={{ display: 'none' }}
      >
        #
      </Link>
    </>
  );
};
