/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Material-UI imports
import { Link, ListItemIcon, Menu, MenuItem } from '@material-ui/core';

// Common imports
import { Framework, Manual } from '../../../../../components/icons';

// Local imports
import { FilterableEntry } from '../types';

export interface MoreActionsMenuProps {
  /**
   * Anchor point for this menu - null will hide menu.
   */
  anchorEl: HTMLElement | null;

  /**
   * State setter that is used for the menu to close itself.
   */
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;

  /**
   * What is the current selected entry.
   */
  selectedEntry?: FilterableEntry | null;

  /**
   * Is the menu being rendered on desktop.  If we leave it up to this
   * component to do the media query then we run the risk of an
   * unnecessary extra render when if it wrongly detects a change.
   */
  isDesktop: boolean;
}

/**
 * Menu displayed when the 'more action' button is clicked for a device.
 */
export const MoreActionsMenu: FC<MoreActionsMenuProps> = (props: MoreActionsMenuProps) => {
  const { selectedEntry } = props;

  return (
    <Menu anchorEl={props.anchorEl} keepMounted open={Boolean(props.anchorEl)} onClose={() => props.setAnchorEl(null)}>
      {selectedEntry?.versions
        .sort((a, b) => {
          if (a.version < b.version) return 1;
          if (a.version > b.version) return -1;
          return 0;
        })
        .map((version) => {
          return (
            <MenuItem key={version.id}>
              <ListItemIcon>
                {selectedEntry.category === 1 ? <Framework color="primary" /> : <Manual color="primary" />}
              </ListItemIcon>
              <Link target="blank" href={version.url}>
                {props.isDesktop ? `${selectedEntry.resourceName} - ${version.version}` : `${version.version}`}
              </Link>
            </MenuItem>
          );
        })}
    </Menu>
  );
};
