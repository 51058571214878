/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import { MemberDetails } from '@wisi-tv/okapi-api';
/**
 * Holds shared types for the tool.
 */

// Third party imports
import * as yup from 'yup';

/**
 * Form fields for the Edit member form.
 */
export interface EditValues {
  userPk?: number | undefined;
  tag: string;
  role?: number;
}

/**
 * Form fields for the Edit tags form.
 */
export interface TagsValues {
  tag: string;
}

/**
 * Form fields for the Invite form.
 */
export interface InviteValues {
  firstName: string;
  lastName: string;
  email: string;
  role: number | null;
}

/**
 * Invite form validation schema.
 */
export const InviteValidationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  email: yup
    .string()
    .email('A valid email is required')
    .required('Email is required')
    .matches(
      /^((?![!"#$%&()*+,/:;<=>?\\^{|}~]+).)*$/,
      'Email must not contain the following characters: ~!#$%^&*()+=[]{}\\/|;:"<>?,'
    ),
  role: yup.number().required('Role is required'),
});


/**
 * Extends Record<string, unknown> because the MaterialTable component requires
 * data to be extended from this generic object type.
 */
export interface TeamMemberDetails extends MemberDetails, Record<string, unknown> {

}