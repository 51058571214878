/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Group = createSvgIcon(
  <g id="WISI-portal" stroke="none">
    <g id="Desktop-HD" transform="translate(-31.000000, -227.000000)">
      <g id="group" transform="translate(31.000000, 227.000000)">
        <path
          d="M18.9132892,11.637838 C17.2473145,10.6800015 15.3519582,10.1349728 13.3887475,9.98901574 C13.7908756,10.1689539 14.195413,10.3447928 14.5762534,10.5637105 C15.019051,10.818551 15.3740729,11.1863384 15.6351703,11.6130264 C16.5683031,11.872182 17.4655849,12.2342159 18.2969541,12.7119296 C18.4711389,12.8123268 18.5794111,13.0137324 18.5794111,13.2381158 L18.5794111,16.1181699 L16.1021682,16.1181699 L16.1021682,17.3568093 L19.1987307,17.3568093 C19.5410592,17.3568093 19.8180504,17.0798181 19.8180504,16.7374896 L19.8180504,13.2381158 C19.8180504,12.5710427 19.4709394,11.9578001 18.9132892,11.637838 Z"
          id="Path"
         />
        <path
          d="M13.9587676,11.637838 C10.0602503,9.39644944 4.80335049,9.39644944 0.90476124,11.637838 C0.346535673,11.9589867 0,12.571654 0,13.2381518 L0,16.7375256 C0,17.0798541 0.27699121,17.3568453 0.619319695,17.3568453 L14.2442091,17.3568453 C14.5865376,17.3568453 14.8635288,17.0798541 14.8635288,16.7375256 L14.8635288,13.2375405 C14.8635288,12.571654 14.5169932,11.9589867 13.9587676,11.637838 L13.9587676,11.637838 Z M13.6249254,16.1182059 L1.23867535,16.1182059 L1.23867535,13.2381518 C1.23867535,13.0137684 1.34694752,12.8123627 1.52170765,12.7119655 C5.04646844,10.6858987 9.81713232,10.6858987 13.3418931,12.7119655 C13.5166892,12.8123627 13.6249254,13.0137684 13.6249254,13.2375405 L13.6249254,16.1182059 Z"
          id="Shape"
         />
        <path
          d="M12.0779027,1.28376778 C12.1806372,1.27301607 12.2824008,1.25467705 12.3863579,1.25467705 C14.0937213,1.25467705 15.4829205,2.6439122 15.4829205,4.35123956 C15.4829205,6.05856692 14.0936853,7.44780208 12.3863579,7.44780208 C12.2824727,7.44780208 12.1807091,7.42935518 12.0779746,7.41860347 C11.8151872,7.81540965 11.4934991,8.16410286 11.1407066,8.48115225 C11.5440213,8.60535421 11.9602093,8.68640551 12.3863579,8.68640551 C14.7765084,8.68640551 16.7215598,6.74138999 16.7215598,4.3512036 C16.7215598,1.96101722 14.7765443,0.0160016991 12.3863579,0.0160016991 C11.9601374,0.0160016991 11.5439494,0.0970530022 11.1406347,0.221254955 C11.4934272,0.538376268 11.8151153,0.886961597 12.0779027,1.28376778 L12.0779027,1.28376778 Z"
          id="Path"
         />
        <path
          d="M7.43183634,8.68644147 C9.82198676,8.68644147 11.7670382,6.74142595 11.7670382,4.35123956 C11.7670382,1.96105318 9.82202272,0.016037658 7.43183634,0.016037658 C5.04164995,0.016037658 3.09663443,1.96105318 3.09663443,4.35123956 C3.09663443,6.74142595 5.04168591,8.68644147 7.43183634,8.68644147 Z M7.43183634,1.25467705 C9.13919966,1.25467705 10.5283989,2.6439122 10.5283989,4.35123956 C10.5283989,6.05856692 9.1391637,7.44780208 7.43183634,7.44780208 C5.72450898,7.44780208 4.33527382,6.05856692 4.33527382,4.35123956 C4.33527382,2.6439122 5.72450898,1.25467705 7.43183634,1.25467705 Z"
          id="Shape"
         />
      </g>
    </g>
  </g>,
  'Group'
);
