/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const CircleEmpty = createSvgIcon(
  <svg height="28" width="28">
    <circle cx="14" cy="14" r="8" stroke="black" strokeWidth="1" fill="none" />
  </svg>,
  'CircleEmpty'
);
