/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import { responsiveFontSizes, createMuiTheme, lighten } from '@material-ui/core';
import './wisi.css';

export const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: ['Source Sans Pro'],
      h1: {
        fontSize: '35px',
        fontWeight: 600,
        marginBottom: '16px',
      },
      h2: {
        fontSize: '24px',
        color: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary').trim() || '#000000',
      },
      h3: {
        fontSize: '24px',
      },
      body1: {
        fontSize: '16px',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': ['Source Sans Pro'],
        },
      },
      MuiIconButton: {
        root: {
          borderRadius: 0
        }
      },
      MuiListItemIcon: {
        root: {
          minWidth: "32px"
        }
      },
      MuiButton: {
        root: {
          textTransform: "none",
          borderRadius: 0,
        },
        contained: {
          color: "white",
          backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-tertiary').trim() || '#000000',
          boxShadow: "none",
          "&.MuiFocusVisible": {
            boxShadow: "none"
          },
          "&:hover": {
            backgroundColor: lighten(getComputedStyle(document.documentElement).getPropertyValue('--ion-color-tertiary').trim() || '#000000', 0.2),
            boxShadow: "none"
          }
        }
      }
    },
    palette: {
      type: 'light',
      text: {
        primary: '#000000',
        secondary:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary').trim() || '#000000',
      },
      grey: {
        100: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-dark').trim() || '#000000',
        500: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-medium').trim() || '#000000',
        900: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-light').trim() || '#000000',
      },
      warning: {
        main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger').trim() || '#000000',
        dark:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger-shade').trim() || '#000000',
        light:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger-tint').trim() || '#000000',
        contrastText:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger-contrast').trim() ||
          '#000000',
      },
      success: {
        main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success').trim() || '#000000',
        dark:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-shade').trim() || '#000000',
        light:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-tint').trim() || '#000000',
        contrastText:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-success-contrast').trim() ||
          '#000000',
      },
      info: {
        main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-tertiary').trim() || '#000000',
        dark:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-tertiary-shade').trim() || '#000000',
        light:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-tertiary-tint').trim() || '#000000',
        contrastText:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-tertiary-contrast').trim() ||
          '#000000',
      },
      primary: {
        main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary').trim() || '#000000',
        dark:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary-shade').trim() || '#000000',
        light:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary-tint').trim() || '#000000',
        contrastText:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary-contrast').trim() ||
          '#000000',
      },
      secondary: {
        main: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary').trim() || '#000000',
        dark:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary-shade').trim() ||
          '#000000',
        light:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary-tint').trim() || '#000000',
        contrastText:
          getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary-contrast').trim() ||
          '#000000',
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff',
      },
    },
  })
);
theme.themeName = 'Wisi';
