/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { logoutCreate } from '@wisi-tv/okapi-api';
import { mutateAsync } from 'redux-query';
import { Logo } from '../logo';
import { ACTION_CLEAR_STATE } from '../../store';


export const FallbackPage: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <Box
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Logo variant="medium" />
      <Box height={theme.spacing(2)}/>
      <Typography variant="h1">Sorry, something unexpected has happened.</Typography>
      <Box height={theme.spacing(2)}/>
      <Box color="#ffffff">
        <Button
          style={{ fontWeight: "bold", borderRadius: 0, boxShadow: "none", backgroundColor: theme.palette.info.main }}
          variant="contained"
          size="large"
          color="inherit"
          onClick={() => {
            // Reset as much state as possible and reload:
            //  * Clear all locally stored state
            //  * Logout which does its best to clear cookies
            //  * Dispatch a special action to clear all of the redux state
            //  * Finally reload the page
            localStorage.clear();
            dispatch(mutateAsync(logoutCreate({})));
            dispatch({ type: ACTION_CLEAR_STATE });
            window.location.reload();
          }}
        >
          Reload Page
        </Button>
      </Box>
    </Box>
  );
};
