/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import * as React from 'react';
import MuiSelect, { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import { FieldProps } from 'formik';

export interface SelectProps extends FieldProps, Omit<MuiSelectProps, 'name' | 'value'> {}

export function fieldToSelect({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting },
  onBlur,
  ...props
}: SelectProps): MuiSelectProps {
  return {
    disabled: disabled ?? isSubmitting,
    ...field,
    ...props,
  };
}

export function Select(props: SelectProps): JSX.Element {
  return <MuiSelect {...fieldToSelect(props)} />;
}

Select.displayName = 'FormikMaterialUISelect';
