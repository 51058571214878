/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  makeStyles,
  Theme,
  createStyles,
  Toolbar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Drawer,
  Divider,
  List,
  useTheme,
  Hidden,
  Box,
  Typography,
  Tooltip,
  useMediaQuery,
  SvgIcon,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import { logoutCreate, usersMeRead, rolesList, organizationsList, OrganizationSummary, UserDetails } from '@wisi-tv/okapi-api';
import { useMutation, useRequest } from 'redux-query-react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Msal from 'msal';
import { faSignOutAlt, faBars, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { AppState } from '../../store';
import { OrganizationSelect } from '../organizationselect';
import { Logo } from '../logo';
import { hasAccess } from '../../utils';
import { setSelectedOrg } from '../../store/slices/system';

const msalConfig = {
  auth: {
    clientId: '20df7369-d6e1-48a8-840d-0b73ca902ac6',
    postLogoutRedirectUri: '',
  },
};

export interface Tool {
  slug: string;
  name: string;
  icon: typeof SvgIcon;
}

export interface HeaderBarProps {
  /**
   * Optional link to jump to when the logo is clicked.
   */
  link?: string;

  /**
   * Optional list of tools to render.
   */
  tools?: Tool[];

  /**
   * Callback from a React.useState that is used by the parent to update it's local state.
   */
  onToolChange: (slug: string) => void;

  /**
   * Callback used to indicate that the drawer width changed.
   */
  onDrawerWidthChange?: (width: string) => void;
}

const getDrawerWidth = (isOpen: boolean): number => {
  if (isOpen) {
    return 160;
  }
    return 0;

};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        minWidth: getDrawerWidth(true),
        maxWidth: getDrawerWidth(true),
        flexShrink: 0,
      },
      '& .MuiDrawer-paper': {
        minWidth: getDrawerWidth(true),
        maxWidth: getDrawerWidth(true),
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: 'none',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '32px',
      },
      '& .MuiListItem-root.Mui-selected': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        marginLeft: getDrawerWidth(true),
      },
      color: '#fff',
      [theme.breakpoints.up('sm')]: {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
      },
      '& .MuiDivider-light': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
      '& .MuiDivider-vertical': {
        flexGrow: 0,
        width: '2px',
        height: theme.spacing(3),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    logo: {
      alignSelf: 'center',
      margin: theme.spacing(3, 0),
    },
    menu: {
      '& .MuiListItemIcon-root': {
        minWidth: '32px',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    organizationSelect: {
      color: 'white',
      padding: theme.spacing(1, 0),
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
  })
);

export const HeaderBar: FC<HeaderBarProps> = (props: HeaderBarProps) => {
  const [selectedTool, setSelectedTool] = useState(0);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState<void | HTMLElement>();
  const [orgAnchorEl, setOrgAnchorEl] = React.useState<void | HTMLElement>();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const doBasicLogoutMutation = useMutation(logoutCreate)[1];
  const dispatch = useDispatch();
  const selectedOrg = useSelector((state: AppState) => state.system.selectedOrg);
  const user = useSelector<AppState, UserDetails>((state: AppState) => state.entities.usersMeRead || {});
  const organizations = useSelector<AppState, OrganizationSummary[]>(
    (state: AppState) => state.entities.organizationsList || []
  );
  const { onDrawerWidthChange } = props;

  const sysAdminAccess = hasAccess(user.groups as Record<string, unknown>, null, ['sys:admin']);

  useRequest(organizationsList({}, { force: true }));
  useRequest(usersMeRead({ force: true }));
  useRequest(rolesList({}, { force: true }));

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (onDrawerWidthChange) onDrawerWidthChange(matches ? `${getDrawerWidth(!mobileOpen)}px` : '0');
    return () => {}
  }, [matches, onDrawerWidthChange, mobileOpen]);

  useEffect(() => {
    if (organizations && organizations.length === 1 && organizations[0].pk !== selectedOrg?.pk) {
      dispatch(setSelectedOrg(organizations[0]));
    }
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAccountAnchorEl();
  };

  const handleMSLogout = async (): Promise<void> => {
    await doBasicLogoutMutation();

    msalConfig.auth.postLogoutRedirectUri = window.location.href;
    const myMsal = new Msal.UserAgentApplication(msalConfig);
    myMsal.logout();

    handleClose();
  };

  const drawer = (
    <>
      <Logo className={classes.logo} link={props.link} variant="small" />
      <List>
        {props.tools?.map((tool, index) => {
          return (
            <ListItem
              selected={index === selectedTool}
              button
              key={tool.slug}
              onClick={() => {
                setSelectedTool(index);
                props.onToolChange(tool.slug);
                if (!matches) handleDrawerToggle();
              }}
            >
              <Tooltip title={tool.name}>
                <ListItemIcon style={{ color: theme.palette.secondary.contrastText }}>
                  <tool.icon color="inherit" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={tool.name} />
            </ListItem>
          );
        })}
      </List>
    </>
  );

  const renderOrgSelect = (): JSX.Element => {
    if (!organizations) return (<></>);

    if (sysAdminAccess) {
      return <OrganizationSelect className={classes.organizationSelect} />;
    }
    if (organizations.length === 0) {
      return (<></>);
    }
    return (
      <div style={{ color: matches ? theme.palette.text.primary : '#fff' }}>
        {organizations.length > 1 ? (
          <>
            <Button
              id="org-button"
              fullWidth
              color="inherit"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setOrgAnchorEl(event.currentTarget)}
              endIcon={<FontAwesomeIcon icon={faAngleDown} />}
            >
              {' '}
              {selectedOrg ? selectedOrg.name : 'Select Organization'}{' '}
            </Button>
            {orgAnchorEl && (
              <Menu
                className={classes.menu}
                anchorEl={orgAnchorEl}
                keepMounted
                open={orgAnchorEl !== null}
                onClose={() => {
                  setOrgAnchorEl();
                }}
              >
                {organizations.map(orgSummary => {
                  return (
                    <MenuItem
                      key={orgSummary.pk}
                      onClick={() => {
                        dispatch(setSelectedOrg(orgSummary));
                        setOrgAnchorEl();
                      }}
                    >
                      <Typography variant="body1">{orgSummary.name}</Typography>
                    </MenuItem>
                  );
                })}
              </Menu>
            )}
          </>
        ) : (
          <Typography color="inherit" variant="body1">
            {organizations[0].name}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <>
      <AppBar elevation={0} className={clsx(classes.appBar)} color="secondary" position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Box flexGrow={1} />
          {renderOrgSelect()}
          <Divider orientation="vertical" />
          <Typography color="inherit" variant="body1">
            {user.firstName && user.firstName.length > 0 ? user.firstName : user.username}
          </Typography>
          <IconButton onClick={handleClick}>
            <Avatar src={user.picture} />
          </IconButton>
        </Toolbar>
        {accountAnchorEl && (
          <Menu
            className={classes.menu}
            anchorEl={accountAnchorEl}
            keepMounted
            open={Boolean(accountAnchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleMSLogout}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </ListItemIcon>
              <Typography variant="inherit">Logout</Typography>
            </MenuItem>
          </Menu>
        )}

        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            className={classes.drawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer className={classes.drawer} variant="permanent" open>
            {drawer}
          </Drawer>
        </Hidden>
      </AppBar>
    </>
  );
};

HeaderBar.defaultProps = {
  showSearchBar: true,
} as Partial<HeaderBarProps>;

