/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core';

import logo_png from './logo.png';

export interface LogoProps extends React.ComponentProps<'div'> {
  /**
   * Size of logo.
   */
  variant: 'icon' | 'small' | 'medium' | 'large';

  /**
   * Optional link to navigate to on click.
   */
  link?: string;
}

interface DivProps {
  size: string;
  link?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: (props: DivProps) => ({
      display: 'inline-block',
      cursor: props.link ? 'pointer' : 'auto',
      minWidth: props.size,
      minHeight: props.size,
      width: props.size,
      height: props.size,
      backgroundImage: `url(${logo_png})`,
      backgroundSize: 'cover',
    }),
  }),
);

export const Logo: FC<LogoProps> = (props: LogoProps) => {
  let size = '89px';
  switch (props.variant) {
    case 'small':
      size = '89px';
      break;
    case 'medium':
      size = '120px';
      break;
    case 'large':
      size = '300px';
      break;
    case 'icon':
      size = '24px';
      break;
    default:
      size = '89px';
  }
  const classes = useStyles({ size, link: props.link });

  return (
    <div
      role="link"
      aria-label="Home"
      tabIndex={0}
      className={clsx(classes.root, props.className)}
      onKeyPress={() => {}}
      onClick={() => {
        if (props.link) window.open(props.link, '_blank');
      }}
    />
  );
};
