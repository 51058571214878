/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Material-UI imports
import { Box, useTheme, Theme, createStyles, makeStyles, useMediaQuery, Button, Typography } from '@material-ui/core';

// API imports
import { Configuration } from '@wisi-tv/okapi-api';

// Common imports
import { CoverPage, CLIP_PATH_NONE } from '../../components/coverpage';
import { Logo } from '../../components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ matches }: { matches: boolean }) => {
      return {
        width: matches ? '350px' : '250px',
        height: matches ? undefined : '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: matches ? 'white' : theme.palette.secondary.main,
        padding: matches ? theme.spacing(5) : theme.spacing(0),
        marginBottom: matches ? undefined : '10vh',
        marginTop: matches ? undefined : '12vh',
        color: matches ? theme.palette.text.primary : theme.palette.secondary.contrastText,

        '& .MuiBox-root': {
          height: matches ? theme.spacing(4) : undefined,
          flexGrow: matches ? undefined : 1,
          minHeight: theme.spacing(1),
        },
      };
    },
  })
);

/**
 * Renders the main login page.
 */
export const LoginPage: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles({ matches });

  return (
    <CoverPage hideLogo hideTerms clipPath={CLIP_PATH_NONE}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <Box className={classes.root}>
          <Logo variant="medium" />
          <Typography style={{ marginTop: `${theme.spacing(2)}px` }} align="center" variant="h3" color="inherit">
            Welcome to
          </Typography>
          <Typography align="center" variant="h3" color="inherit">
            WISI Support Portal
          </Typography>
          <Box />
          <Button
            disableElevation
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            style={{ borderRadius: 0 }}
            onClick={() => {
              window.location.href = `${Configuration.basePath}/login/azuread-tenant-oauth2/?next=${window.location.href}`;
            }}
          >
            Get Started
          </Button>
        </Box>
      </Box>
    </CoverPage>
  );
};
