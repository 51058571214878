/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import { OrganizationSummary, logoutCreate } from '@wisi-tv/okapi-api';
import {
  actionTypes,
  mutateAsync,
  updateEntities,
  MutateAsyncAction,
  RequestFailureAction,
  UpdateEntitiesAction,
} from 'redux-query';
import {
  takeEvery,
  put,
  call,
  PutEffect,
  ForkEffect,
  SimpleEffect,
  CallEffectDescriptor } from 'redux-saga/effects';
import { setSelectedOrg } from '../slices/system';

function* handleFailure(
  action: RequestFailureAction,
): Generator<
  | PutEffect<
      UpdateEntitiesAction<{
        refresh_token: void;
      }>
    >
  | PutEffect<{
      payload: OrganizationSummary | null;
      type: string;
    }>
  | PutEffect<MutateAsyncAction<unknown>>
  | SimpleEffect<'CALL', CallEffectDescriptor<void>>,
  void,
  unknown
> {
  switch (action.status) {
    case 401:
      yield put(
        updateEntities({
          refresh_token: () => {},
        }),
      );
      break;
    case 403: {
      // Make us logout
      yield put(mutateAsync(logoutCreate({})));
      // Clear our selected org
      yield put(setSelectedOrg(null));
      // Redirect us to the login page if not already there
      yield call(() => {
        if (!window.location.href.endsWith('/login')) window.location.href = '/login';
      });
      break;
    }
    default:
      break;
  }
}

function* watchApiFailure(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(actionTypes.REQUEST_FAILURE, handleFailure);
  yield takeEvery(actionTypes.MUTATE_FAILURE, handleFailure);
}

export { watchApiFailure };
