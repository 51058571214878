/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Third party imports
import { Column } from 'material-table';

// Common imports
import { MaterialTable } from '../../../../../components';
import { Add, Edit, Remove, Tag } from '../../../../../components/icons';

// Local imports
import { TeamMemberDetails } from '../types';

export interface TeamTableProps {
  /**
   * Optional className to provide styling at the root level.
   */
  className?: string;

  /**
   * This is the unique identifier for the logged in user.
   */
  loggedInUserPk?: number;

  /**
   * This is a map from internally used role-id to human presentable role name.
   */
  roleMap: { [a: string]: string };

  /**
   * Indicates if the loggedInUser has org admin access.
   */
  hasOrgAdminAccess: boolean;

  /**
   * List of members on the team.
   */
  members: TeamMemberDetails[];

  /**
   * Is paging enabled or not for this table.
   */
  paging?: boolean;

  /**
   * Page size if paging is enabled.
   */
  pageSize?: number;

  /**
   * Is the table being rendered on desktop.  If we leave it up to this
   * component to do the media query then we run the risk of an
   * unnecessary extra render when if it wrongly detects a change.
   */
  isDesktop: boolean;

  /**
   * Called when the button to invite a new member is clicked.
   */
  onInviteMember: (event: React.MouseEvent<HTMLButtonElement>, selectedRows: TeamMemberDetails[]) => void;

  /**
   * Called when the button to edit a member is clicked.
   */
  onEditMember: (event: React.MouseEvent<HTMLButtonElement>, selectedRows: TeamMemberDetails[]) => void;

  /**
   * Called when the button to edit user(s) tag is clicked.
   */
  onEditTags: (event: React.MouseEvent<HTMLButtonElement>, selectedRows: TeamMemberDetails[]) => void;

  /**
   * Called when the button to delete a team member is clicked.
   */
  onDeleteMember: (event: React.MouseEvent<HTMLButtonElement>, selectedRows: TeamMemberDetails[]) => void;
}

/**
 * Table used to present all of the team members.
 */
export const TeamTable: FC<TeamTableProps> = (props: TeamTableProps) => {
  const columns: Column<TeamMemberDetails>[] = [
    {
      title: 'Name',
      field: 'name',
      editable: 'never',
      cellStyle: (data, rowData) => {
        return {
          fontWeight: rowData.userPk === props.loggedInUserPk ? 'bold' : 'normal',
        };
      },
    },
    {
      title: 'Email',
      field: 'emailAddress',
      editable: 'never',
      searchable: true,
      hidden: !props.isDesktop,
    },
    {
      title: 'Tag',
      field: 'tag',
      editable: 'never',
      searchable: true,
      hidden: !props.isDesktop,
    },
    {
      title: 'Date Invited',
      field: 'dateInvited',
      type: 'date',
      editable: 'never',
      searchable: true,
      hidden: !props.isDesktop,
    },
    {
      title: 'Last Login',
      field: 'lastLogin',
      type: 'date',
      editable: 'never',
      searchable: true,
      hidden: !props.isDesktop,
    },
    {
      title: 'Role',
      field: 'role',
      lookup: props.roleMap,
      searchable: true,
      editable: 'never',
    },
  ];

  const actions = props.hasOrgAdminAccess
    ? [
        // Invite member is a "free action" meaning it is always shown
        {
          icon: () => <Add color="primary" />,
          tooltip: 'Invite member',
          onClick: (event: React.MouseEvent<HTMLButtonElement>, selection: TeamMemberDetails | TeamMemberDetails[]) => {
            props.onInviteMember(event, Array.isArray(selection) ? selection : [selection]);
          },
          isFreeAction: true,
        },
        // Edit member is a row only button - hidden when using the selection checkbox
        (rowData: TeamMemberDetails | TeamMemberDetails[]) => {
          const hidden = Array.isArray(rowData);

          return {
            hidden,
            icon: () => <Edit color="primary" />,
            tooltip: 'Edit member',
            onClick: (event: React.MouseEvent<HTMLButtonElement>, selection: TeamMemberDetails | TeamMemberDetails[]) => {
              props.onEditMember(event, Array.isArray(selection) ? selection : [selection]);
            },
          };
        },
        // Edit tags is a only shown when using the selection boxes
        (rowData: TeamMemberDetails | TeamMemberDetails[]) => {
          const hidden = !Array.isArray(rowData);

          return {
            hidden,
            icon: () => <Tag color="primary" />,
            tooltip: 'Edit tag',
            onClick: (event: React.MouseEvent<HTMLButtonElement>, selection: TeamMemberDetails | TeamMemberDetails[]) => {
              props.onEditTags(event, Array.isArray(selection) ? selection : [selection]);
            },
          };
        },
        // Delete member is a row only action and it is hidden for the loggedInUser
        (rowData: TeamMemberDetails | TeamMemberDetails[]) => {
          let hidden = true;

          if (!Array.isArray(rowData)) {
            hidden = rowData.userPk === props.loggedInUserPk;
          }

          return {
            hidden,
            icon: () => <Remove color="primary" />,
            tooltip: 'Remove member',
            onClick: (event: React.MouseEvent<HTMLButtonElement>, selection: TeamMemberDetails | TeamMemberDetails[]) => {
              props.onDeleteMember(event, Array.isArray(selection) ? selection : [selection]);
            },
          };
        },
      ]
    : [];

  return (
    <div className={props.className}>
      <MaterialTable
        options={{
          maxBodyHeight: 'calc(100vh - 250px)',
          actionsColumnIndex: -1,
          selection: true,
          searchFieldVariant: 'outlined',
          showTextRowsSelected: false,
          searchFieldStyle: { width: props.isDesktop ? '500px' : '200px' },
          paging: props.paging,
          pageSize: props.pageSize,
          pageSizeOptions: [props.pageSize || 0],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: 'Search name, email, tag, ...',
          },
        }}
        searchFieldAlignment="left"
        columns={columns}
        data={props.members}
        actions={actions}
      />
    </div>
  );
};

TeamTable.defaultProps = {
  pageSize: 100,
} as Partial<TeamTableProps>;
