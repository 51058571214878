/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Remove = createSvgIcon(
  <g id="WISI-portal" stroke="none">
    <g id="Desktop-HD" transform="translate(-1266.000000, -313.000000)">
      <g id="remove_icon" transform="translate(1257.000000, 308.000000)">
        <g id="Group-3" transform="translate(9.360000, 5.850000)">
          <g id="Clip-2" />
          <path
            d="M4.50445601,16.2022583 C4.85400568,16.2022583 5.13736798,15.918896 5.13736798,15.5693463 L5.13736798,7.733232 C5.13736798,7.38368233 4.85400568,7.10032003 4.50445601,7.10032003 C4.15494425,7.10032003 3.87158195,7.38368233 3.87158195,7.733232 L3.87158195,15.5693463 C3.87158195,15.918896 4.15494425,16.2022583 4.50445601,16.2022583"
            id="Fill-4"
            mask="url(#mask-2)"
           />
          <path
            d="M7.05050892,16.2022583 C7.40002068,16.2022583 7.68338298,15.918896 7.68338298,15.5693463 L7.68338298,7.733232 C7.68338298,7.38368233 7.40002068,7.10032003 7.05050892,7.10032003 C6.70095925,7.10032003 6.41759695,7.38368233 6.41759695,7.733232 L6.41759695,15.5693463 C6.41759695,15.918896 6.70092134,16.2022583 7.05050892,16.2022583"
            id="Fill-5"
            mask="url(#mask-2)"
           />
          <path
            d="M9.59652392,16.2022583 C9.94603568,16.2022583 10.229398,15.918896 10.229398,15.5693463 L10.229398,7.733232 C10.229398,7.38368233 9.94603568,7.10032003 9.59652392,7.10032003 C9.24697426,7.10032003 8.96361196,7.38368233 8.96361196,7.733232 L8.96361196,15.5693463 C8.96361196,15.918896 9.24697426,16.2022583 9.59652392,16.2022583"
            id="Fill-6"
            mask="url(#mask-2)"
           />
          <path
            d="M11.4586407,18.14311 L2.64260459,18.14311 L2.09210461,5.15950625 L12.0091028,5.15950625 L11.4586407,18.14311 Z M1.26578603,3.21266509 L12.8353834,3.21266509 L12.8353834,3.89372022 C12.260357,3.89372022 1.61029393,3.89372022 1.26578603,3.89372022 L1.26578603,3.21266509 Z M5.36557414,1.94687906 L8.73559534,1.94687906 L8.73559534,1.26578603 L5.36557414,1.26578603 L5.36557414,1.94687906 Z M13.4683333,1.94687906 L10.0014572,1.94687906 L10.0014572,0.632911968 C10.0014572,0.2833623 9.71805697,0 9.36854521,0 L4.73270008,0 C4.38315041,0 4.09978811,0.2833623 4.09978811,0.632911968 L4.09978811,1.94687906 L0.632911968,1.94687906 C0.2833623,1.94687906 0,2.23024136 0,2.57979103 L0,4.52663219 C0,4.87614395 0.2833623,5.15950625 0.632911968,5.15950625 L0.825181344,5.15950625 L1.40365742,18.8028229 C1.41798665,19.1416067 1.6968379,19.408896 2.03596286,19.408896 L12.0652066,19.408896 C12.4042937,19.408896 12.6831449,19.1416067 12.697512,18.802785 L13.2759502,5.15946834 L13.4682575,5.15946834 C13.8177693,5.15946834 14.1011695,4.87610604 14.1011695,4.52659428 L14.1011695,2.57979103 C14.1011695,2.23020346 13.817883,1.94687906 13.4683333,1.94687906 L13.4683333,1.94687906 Z"
            id="Fill-1"
            mask="url(#mask-2)"
           />
        </g>
        <polygon id="Shape" points="0 0 31.59 0 31.59 31.59 0 31.59" fill="none" />
      </g>
    </g>
  </g>,
  'Remove'
);
