/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { FC } from 'react';
import { makeStyles, Theme, createStyles, Box, BoxProps } from '@material-ui/core';

export interface ContentProps extends BoxProps {
  /**
   * Is there some padding around the edges of the content.
   */
  padding?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: ContentProps) => ({
      height: '100%',
      background: theme.palette.background.default,
      color: theme.palette.grey[100],
      paddingLeft: props.padding ? `${theme.spacing(2)}px` : '0',
      paddingRight: props.padding ? `${theme.spacing(2)}px` : '0',
      paddingTop: props.padding ? `${theme.spacing(2)}px` : '0',
      padddingBottom: props.padding ? `${theme.spacing(2)}px` : '0',
    }),
  })
);

export const Content: FC<ContentProps> = (props: BoxProps) => {
  const classes = useStyles(props);

  return <Box className={classes.root}>{props.children}</Box>;
};
