/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const CreateTicket = createSvgIcon(
  <g id="WISI-Cloud" stroke="none">
    <g id="open-ticket">
      <path
        d="M20.8478378,7.4359316 C20.5275871,6.91870256 19.9611135,6.6100388 19.3325209,6.6100388 L17.7634534,6.6100388 L17.7634534,3.73180576 C17.7634534,3.03707096 17.1773463,2.47204572 16.4570237,2.47204572 L8.77599568,2.47204572 C8.7647306,2.47204572 8.75716688,2.46882712 8.7541092,2.46689596 L7.37944516,0.544265281 C7.1356362,0.20341552 6.73363304,-7.31824912e-17 6.30394996,-7.31824912e-17 L1.30642976,-7.31824912e-17 C0.58594612,-7.31824912e-17 0,0.56518616 0,1.25976004 L0,15.0672322 C0,15.7782209 0.600268879,16.3567643 1.33829388,16.3567643 L16.8849365,16.3567643 C17.1271362,16.3567643 17.3363452,16.2169161 17.4375702,16.0139834 L17.438053,16.0141443 L20.9218656,9.01706884 C21.1745257,8.5099784 21.1466848,7.91888252 20.8478378,7.4359316 Z M1.30642976,1.2359424 L6.30394996,1.2359424 C6.34096384,1.2359424 6.36606896,1.25219632 6.37395452,1.26330048 L7.75071064,3.18866704 C7.9830936,3.5137456 8.36642884,3.70798812 8.77599568,3.70798812 L16.4570237,3.70798812 C16.5001529,3.70798812 16.5220394,3.72826532 16.527511,3.73550716 L16.527511,6.6100388 L5.3433588,6.6100388 C4.64975048,6.6100388 4.01890488,7.0009378 3.73615088,7.6060346 L1.2359424,12.9564743 L1.2359424,1.26346144 C1.241414,1.2562196 1.26330048,1.2359424 1.30642976,1.2359424 Z M19.8154718,8.46620544 L16.502245,15.120661 L1.58886188,15.120661 L4.85590184,8.12937896 C4.93507936,7.95991968 5.13109212,7.8459812 5.3433588,7.8459812 L19.3325209,7.8459812 C19.529982,7.8459812 19.7036255,7.93594108 19.7969649,8.08657156 C19.8494281,8.17138168 19.8951322,8.30608008 19.8154718,8.46620544 Z"
        id="Shape"
       />
    </g>
  </g>,
  'CreateTicket'
);
