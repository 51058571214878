/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';

// Material-UI imports
import { Container, Typography, Box, useTheme, CircularProgress, useMediaQuery, Link } from '@material-ui/core';

// API imports
import { organizationsDevicesList, FoldableDevice, ContentEntrySummary } from '@wisi-tv/okapi-api';

// Common imports
import { AppState } from '../../../../store';
import { hasAccess } from '../../../../utils';

// Local imports
import { DeviceWithSearchableModules } from './types';
import { DevicesTable, MoreActionsMenu } from './components';

export interface DevicesProps {
  hide?: boolean;
}

/**
 * Main Devices tool.  Shows all of the devices the selected org owns and
 * will allow for management and monitoring of the devices.
 */
export const Devices: FC<DevicesProps> = (props: DevicesProps) => {
  /** **************************************************************************
   *  Initial theme and hook setup
   ************************************************************************** */
  const theme = useTheme();
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));

  /** **************************************************************************
   * Redux query selectors and dispatchers
   ************************************************************************** */
  const userRead = useSelector<AppState, Record<string, Record<string, unknown>>>((state: AppState) => state.entities.usersMeRead);
  const { groups } = userRead || {};
  const selectedOrg = useSelector((state: AppState) => state.system.selectedOrg);
  const devices = useSelector<AppState, FoldableDevice[]>(
    (state: AppState) => state.entities.organizationsDevicesList || []
  );
  const entries = useSelector<AppState, ContentEntrySummary[]>((state: AppState) => state.entities.entriesList || []);
  const devicesLoading = useRequest(
    selectedOrg?.pk
      ? organizationsDevicesList({ parentLookupOrgId: selectedOrg.pk.toString() }, { force: true })
      : undefined
  )[0];
  const dispatch = useDispatch();

  /** **************************************************************************
   * Local state
   ************************************************************************** */

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedDevice, setSelectedDevice] = useState<DeviceWithSearchableModules | null>(null);

  /** **************************************************************************
   * Event handlers
   ************************************************************************** */

  const onGetLicense = useCallback(
    () => {
      // alert('Download License');
    },
    []
  );

  const onMoreActions = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      device: DeviceWithSearchableModules | DeviceWithSearchableModules[]
    ) => {
      if (Array.isArray(device)) {
        setSelectedDevice(device[0]);
      } else {
        setSelectedDevice(device);
      }
      setAnchorEl(event.currentTarget);
    },
    []
  );

  /** **************************************************************************
   * Local variables - typically derived from state and used in renders
   ************************************************************************** */

  const adminAccess = useMemo(() => hasAccess(groups, selectedOrg, ['org:admin']), [groups, selectedOrg]);

  const resellerAccess = useMemo(() => hasAccess(groups, selectedOrg, ['org:reseller']), [groups, selectedOrg]);

  /** **************************************************************************
   * Render functions
   ************************************************************************** */

  /**
   * Render the main tool - this really only should get re-rendered if
   * the adminAccess level changes, the list of devices changes, or if
   * the media query determining the screen size changes.
   */
  const renderTool = useMemo(() => {
    return (
      <Container maxWidth="lg">
        <Typography color="primary" variant="h1">
          Devices
        </Typography>
        <DevicesTable
          isDesktop={mdMatches}
          devices={devices}
          hasOrgAdminAccess={adminAccess}
          onGetLicense={onGetLicense}
          onMoreActions={onMoreActions}
          paging={devices.length > 50}
          pageSize={50}
        />
      </Container>
    );
  }, [onGetLicense, onMoreActions, adminAccess, devices, mdMatches]);

  /**
   * Render what happens when we have no devices.  Only re-computed if
   * the theme changes.
   */
  const renderNoDevices = useMemo(() => {
    return (
      <Container fixed>
        <Box height={theme.spacing(20)} />
        <span>
          <Typography display="inline" variant="h1">You can register devices by contacting </Typography>
          <Link underline="always" variant="h1" href='https://wisi.tv/support'>WISI support</Link>
        </span>
      </Container>
    );
  }, [theme]);

  /**
   * Render what happens during device loading.  This never needs to get
   * re-computed.
   */
  const renderLoading = useMemo(() => {
    return (
      <Container fixed>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }, []);

  /** **************************************************************************
   * Main Render
   ************************************************************************** */

  if (props.hide) {
    return <></>
  }

  return (
    <>
      {devicesLoading.isPending ? renderLoading : (devices.length > 0 ? renderTool : renderNoDevices)}
      <MoreActionsMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedOrg={selectedOrg}
        selectedDevice={selectedDevice}
        entries={entries}
        dispatch={dispatch}
        hasAccessToDownloadLicense={!resellerAccess || adminAccess}
        userName={userRead && `${userRead.firstName} ${userRead.lastName}` || `Not defined`}
      />
    </>
  );
};
