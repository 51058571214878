/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import { Configuration } from '@wisi-tv/okapi-api';

Configuration.basePath = process.env.REACT_APP_API_BASE_PATH || 'http://localhost:8080';
Configuration.credentials = 'include';
