/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationSummary } from '@wisi-tv/okapi-api';

const LOCAL_STORAGE_SELECTED_ORG = 'localSelectedOrganization';
const LOCAL_STORAGE_CURRENT_USER = 'localCurrentUser';

// Describing the shape of the system's slice of state
export interface SystemState {
  currentUser?: string;
  isLoggedIn?: boolean;
  selectedOrg: OrganizationSummary | null;
}

const getSelectedOrg = (): OrganizationSummary | null => {
  const selectedOrg = localStorage.getItem(LOCAL_STORAGE_SELECTED_ORG);

  if (typeof(selectedOrg) === "string") {
    return JSON.parse(selectedOrg);
  }

  return selectedOrg;
};

// Initial state
const initialState: SystemState = {
  isLoggedIn: undefined,
  selectedOrg: getSelectedOrg(),
};

const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<string>) {
      const previousUser = localStorage.getItem(LOCAL_STORAGE_CURRENT_USER);
      if (action.payload !== previousUser) {
        localStorage.setItem(LOCAL_STORAGE_CURRENT_USER, action.payload);
        /* eslint-disable no-param-reassign */
        state.selectedOrg = null;
        /* eslint-enable no-param-reassign */
        localStorage.removeItem(LOCAL_STORAGE_SELECTED_ORG);
      }
      /* eslint-disable no-param-reassign */
      state.currentUser = action.payload;
      /* eslint-enable no-param-reassign */
    },
    setIsLoggedIn(state, action: PayloadAction<boolean>) {
      /* eslint-disable no-param-reassign */
      state.isLoggedIn = action.payload;
      /* eslint-enable no-param-reassign */
    },
    setSelectedOrg(state, action: PayloadAction<OrganizationSummary | null>) {
      if (action.payload) {
        localStorage.setItem(LOCAL_STORAGE_SELECTED_ORG, JSON.stringify(action.payload));
      } else {
        localStorage.removeItem(LOCAL_STORAGE_SELECTED_ORG);
      }
      /* eslint-disable no-param-reassign */
      state.selectedOrg = action.payload;
      /* eslint-enable no-param-reassign */
    },
  },
});

export const systemReducer = systemSlice.reducer;

export const { setIsLoggedIn, setSelectedOrg, setCurrentUser } = systemSlice.actions;
