/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { ReactNode, Component, ErrorInfo } from 'react';

import StackdriverErrorReporter from 'stackdriver-errors-js';

import { Configuration } from '@wisi-tv/okapi-api';
import { FallbackPage } from '../fallbackpage';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  errorHandler: StackdriverErrorReporter; // Responsible for formatting and sending error to remote service.

  constructor(props: Props) {
    super(props);

    // Setup the remote error dispatcher library.  Tell it to send errors to our
    // local /error endpoint and tell our endpoint where the API is being hosted so that
    // we can use the API to verify the request.  Also disable its internal handling
    // of uncaught errors and unhandled promises.  We want to take control of that
    // in our error boundary.
    this.errorHandler = new StackdriverErrorReporter();
    this.errorHandler.start({
      reportUnhandledPromiseRejections: false,
      reportUncaughtExceptions: false,
      targetUrl: `/error?apiPath=${Configuration.basePath}`,
    });

    // Initialize our state to not render the fallback page.
    this.state = { hasError: false };
  }

  public componentDidMount(): void {
    // Setup this error boundary component to catch additional errors.
    window.addEventListener('error', this.handleOnError, true);
    window.addEventListener('unhandledrejection', this.handleOnRejection, true);
  }

  public componentDidCatch(unusedError: Error, errorInfo: ErrorInfo): void {
    // Send to logging service
    this.report(errorInfo.componentStack);
  }

  public componentWillUnmount(): void {
    // If this error boundary isn't used at the top level then we need to
    // make sure we handle cleaning up our event listeners if we are unmounted.
    window.removeEventListener('error', this.handleOnError, true);
    window.removeEventListener('unhandledrejection', this.handleOnRejection, true);
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  private handleOnError = (errorEvent: ErrorEvent): void => {
    // Send to logging service
    this.report(errorEvent.error);
  };

  private handleOnRejection = (errorEvent: PromiseRejectionEvent): void => {
    // Send to logging service
    this.report(errorEvent.reason);

    // Unhandled rejections should not happen because of the strong eslint rules.  If we
    // hit this then there is a good chance that we have hit an unrecoverable
    // condition and likely want to inform the user and give them a reload option.
    this.setState({ hasError: true });
  };

  private async report(message: string): Promise<void> {
    try {
      this.errorHandler.report(message).catch((error) => {
        console.error(error);
      });
    } catch {
      console.error('Failed to send an error to the reporting service');
    }
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <FallbackPage />;
    }
    return this.props.children;
  }
}
