/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { QueriesState } from 'redux-query';
import { FormCard, FormCardProps } from './FormCard';
import { SimpleDialog } from '../dialogs';

export interface DialogFormCardProps<Values> extends Omit<FormCardProps<Values>, 'onSubmit'> {
  /**
   * Result from a useState hook indicating if the dialog is open.
   */
  isOpen: boolean;

  /**
   * Result from a useState hook to control the open/close state of the dialog.
   */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Initial values for the form.
   */
  values: Values;

  /**
   * Optional validation schema for the form.
   */
  validationSchema?: unknown;

  /**
   * Performs the actual submit of the form.
   */
  doSubmit: (values: Values) => Promise<void>;

  /**
   * Optional title for the dialog to go below the logo.
   */
  title?: string;

  /**
   * Optional subtitle to go below the optional title.
   */
  subTitle?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& form': {
        marginTop: theme.spacing(1),
      },
    },
  })
);

export function DialogFormCard<Values>(props: DialogFormCardProps<Values>): JSX.Element {
  const {
    isOpen,
    setIsOpen,
    values,
    doSubmit,
    validationSchema,
    title,
    subTitle,
    children,
    submitLabel,
    checkDirty,
    queryKey = 'no_query_key',
    ...other
  } = props;
  const [lastQueryCount, setLastQueryCount] = useState(0);
  const classes = useStyles();
  const querySelector = useSelector((state: Record<string, QueriesState>) => state.queries[queryKey]);
  const queryStatus = querySelector && querySelector.status || 0;
  const queryCount = querySelector && querySelector.queryCount || 0;

  // Keep track of the last queryCount since this number gets increased for every
  // query.  That allows us to only do the auto close if we are in the middle
  // of an active query.
  if ((queryCount > lastQueryCount) && (queryStatus < 400) && (queryStatus > 0)) {
    setLastQueryCount(queryCount);
    setTimeout(() => setIsOpen(false));
  }

  return (
    <SimpleDialog
      title={title}
      subTitle={subTitle}
      showOk={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={() => setIsOpen(false)}
    >
      <FormCard<Values>
        {...other}
        className={classes.root}
        queryKey={queryKey}
        hideCard
        values={values}
        validationSchema={validationSchema}
        checkDirty={checkDirty}
        submitLabel={submitLabel}
        showCancel
        onCancel={() => setIsOpen(false)}
        onSubmit={async (submitValues, helpers) => {
          helpers.setSubmitting(true);
          await doSubmit(submitValues);
          helpers.setSubmitting(false);
        }}
        width="100%"
        submitButtonProps={{
          color: 'primary',
        }}
      >
        {children}
      </FormCard>
    </SimpleDialog>
  );
}
