/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { FC, useState } from 'react';
import {
  Button,
  Backdrop,
  Card,
  CardActions,
  Box,
  CardHeader,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  useMediaQuery,
  useTheme,
  CardContent,
  LinearProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
// import { useRequest, useMutation } from 'redux-query-react';
import {
  // organizationsList,
  // organizationsCreate,
  OrganizationSummary,
  // organizationsUpdate,
  // organizationsDelete,
  // usersMeRead,
} from '@wisi-tv/okapi-api';
import { ArrowDropDown } from '@material-ui/icons';
import { MaterialTable } from '../materialtable';
import { setSelectedOrg } from '../../store/slices/system';
import { AppState } from '../../store';

const MAX_HEIGHT_SM = '160px';
const MAX_HEIGHT_MD = '300px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: ({ matchesMd, matchesSm }: Record<string, string>) => ({
      maxWidth: matchesMd ? '700px' : (matchesSm ? '500px' : '300px'),
      minWidth: matchesMd ? '700px' : (matchesSm ? '500px' : '300px'),
    }),
    rootBackdrop: {
      zIndex: theme.zIndex.drawer + 1,
    },
    moreActionsPopper: {
      zIndex: theme.zIndex.drawer + 2,
    },
    table: ({ matchesMd }: Record<string, string>) => ({
      maxHeight: matchesMd ? MAX_HEIGHT_MD : MAX_HEIGHT_SM,
    }),
  }),
);

export interface ColumnType {
  title: string,
  field: string
}

export interface OrganizationSelectProps {
  className?: string;
}

export const OrganizationSelect: FC<OrganizationSelectProps> = (props: OrganizationSelectProps) => {
  // const ORG_CREATE_QUERY_KEY = 'orgCreate';
  // const ORG_UPDATE_QUERY_KEY = 'orgUpdate';
  // const ORG_DELETE_QUERY_KEY = 'orgDelete';

  // Styling
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles({ matchesMd, matchesSm });

  // Redux selectors and dispatchers
  const selectedOrg = useSelector((state: AppState) => state.system.selectedOrg);
  const dispatch = useDispatch();

  // Local state
  const [currentSelectedOrg, setCurrentSelectedOrg] = useState<OrganizationSummary | null>(selectedOrg);
  const [organizationCardOpen, setOrganizationCardOpen] = useState(false);
  // const [moreActionsOpen, setMoreActionsOpen] = useState(false);
  // const [newOrgFormOpen, setNewOrgFormOpen] = useState(false);
  // const [editOrgFormOpen, setEditOrgFormOpen] = useState(false);
  // const [deleteOrgFormOpen, setDeleteOrgFormOpen] = useState(false);

  const organizations = useSelector<AppState, OrganizationSummary[]>(
    (state: AppState) => state.entities.organizationsList || [],
  );
  // const forceOrgRefresh = useRequest(organizationsList({}))[1];
  // const forceUserRead = useRequest(usersMeRead({ force: true }))[1];
  // const doOrgCreateMutation = useMutation(organizationsCreate)[1];
  // const doOrgUpdateMutation = useMutation(organizationsUpdate)[1];
  // const doOrgDeleteMutation = useMutation(organizationsDelete)[1];

  // Other local variables
  // const moreActionsRef = React.useRef<HTMLButtonElement | null>(null);

  /**
   * Called when the `Select` button is pressed to set the organization.
   */
  const onSelect = ():void => {
    dispatch(setSelectedOrg(currentSelectedOrg));
    setOrganizationCardOpen(false);
  };

  /**
   * Called when a new organization if selected.
   *
   * @param values
   */
  // const onNewOrganization = async (values: { pk: number; name: string }):Promise<void> => {
  //   const response = await doOrgCreateMutation(
  //     {
  //       data: {
  //         name: values.name || "",
  //       },
  //     },
  //     {
  //       queryKey: ORG_CREATE_QUERY_KEY,
  //     }
  //   )
  //   if (response && (response.status === 201)) {
  //     forceUserRead();
  //     forceOrgRefresh();
  //     setNewOrgFormOpen(false);
  //   }
  // };
  /**
   * Called when an organization should be edited.
   *
   * @param values
   */
  // const onEditOrganization = async (values: { pk?: number; name: string }):Promise<void> => {
  //   if (!currentSelectedOrg?.pk) return;
  //   const response = await doOrgUpdateMutation(
  //     {
  //       orgId: currentSelectedOrg.pk.toString(),
  //       data: {
  //         name: values.name || '',
  //       },
  //     },
  //     {
  //       queryKey: ORG_UPDATE_QUERY_KEY,
  //     },
  //   )
  //     if (response && (response.status === 200)) {
  //       forceOrgRefresh();
  //       setEditOrgFormOpen(false);
  //     }
  // };

  /**
   * Called when an organization is to be deleted.
   *
   * @param values
   */
  // const onDeleteOrganization = async ():Promise<void> => {
  //   if (!currentSelectedOrg?.pk) return;
  //   const response = await doOrgDeleteMutation(
  //     { orgId: currentSelectedOrg.pk.toString() },
  //     {
  //       queryKey: ORG_DELETE_QUERY_KEY,
  //     },
  //   )
  //   if (response && (response.status === 204)) {
  //     if (selectedOrg?.pk === currentSelectedOrg?.pk) {
  //       dispatch(setSelectedOrg(null));
  //     }
  //     forceOrgRefresh();
  //     setCurrentSelectedOrg(null);
  //     setDeleteOrgFormOpen(false);
  //   }
  // };

  const columns: ColumnType[] = matchesMd
    ? [
        { title: 'Name', field: 'name' },
        { title: 'Customer', field: 'customer' },
      ]
    : [{ title: 'Name', field: 'name' }];

  return (
    <div style={{ color: matchesSm ? theme.palette.text.primary : '#fff' }} className={props.className}>
      <Button
        fullWidth
        style={{ fontSize: theme.typography.body1.fontSize }}
        color="inherit"
        onClick={() => setOrganizationCardOpen(true)}
        endIcon={<ArrowDropDown />}
      >
        {selectedOrg ? selectedOrg.name : 'Select Organization'}
      </Button>
      <Backdrop className={classes.rootBackdrop} open={organizationCardOpen}>
        <Card className={classes.card}>
          <CardHeader
            disableTypography
            title={<Typography variant="h3">{organizations.length > 0 ? "Select Organization" : "Loading Organizations"}</Typography>}
            // action={
            //   <div>
            //     <Button
            //       onClick={() => {
            //         setNewOrgFormOpen(true);
            //       }}
            //     >
            //       New Organization
            //     </Button>
            //     <IconButton
            //       ref={moreActionsRef}
            //       disabled={!currentSelectedOrg}
            //       size="small"
            //       onClick={() => setMoreActionsOpen(true)}
            //     >
            //       <FontAwesomeIcon icon={faEllipsisV} />
            //     </IconButton>
            //   </div>
            // }
          />
          <CardContent>
            {organizations.length > 0 && <MaterialTable
              searchFieldAlignment="left"
              minBodyHeight={matchesMd ? MAX_HEIGHT_MD : MAX_HEIGHT_SM}
              options={{
                searchFieldVariant: 'outlined',
                showTextRowsSelected: false,
                maxBodyHeight: matchesMd ? MAX_HEIGHT_MD : MAX_HEIGHT_SM,
                paging: true,
                pageSize: 50,
                pageSizeOptions: [50],
              }}
              columns={columns}
              onSelectionChange={(data, rowData: unknown) => {
                if (data.length > 0 && rowData) {
                  setCurrentSelectedOrg(rowData as OrganizationSummary);
                } else {
                  setCurrentSelectedOrg(null);
                }
              }}
              data={!organizationCardOpen ? [] : organizations.map((organization: OrganizationSummary) => {
                return {
                  ...organization,
                  tableData: {
                    checked: organization.pk === currentSelectedOrg?.pk,
                  },
                };
              })}
            />}
            {organizations.length === 0 && <LinearProgress />}
          </CardContent>
          <CardActions>
            <Box flexGrow={1} />
            <Button onClick={() => setOrganizationCardOpen(false)}>Cancel</Button>
            <Button disabled={!currentSelectedOrg} onClick={onSelect}>
              Select
            </Button>
          </CardActions>
        </Card>
      </Backdrop>
      {/* <DialogFormCard
        title="Create Organization"
        values={{ pk: 0, name: '' }}
        setIsOpen={setNewOrgFormOpen}
        isOpen={newOrgFormOpen}
        doSubmit={onNewOrganization}
        showCancel
        submitLabel="Create"
        queryKey={ORG_CREATE_QUERY_KEY}
      >
        <Field type="hidden" name="pk" />
        <Field component={TextField} fullWidth label="Name" name="name" required />
      </DialogFormCard>
      <DialogFormCard
        title="Edit Organization"
        values={{
          pk: currentSelectedOrg?.pk,
          name: currentSelectedOrg?.name || '',
        }}
        width={matchesMd ? '500px' : '350px'}
        isOpen={editOrgFormOpen}
        setIsOpen={setEditOrgFormOpen}
        doSubmit={onEditOrganization}
        showCancel
        submitLabel="Update"
        queryKey={ORG_UPDATE_QUERY_KEY}
      >
        <Field type="hidden" name="pk" />
        <Field component={TextField} fullWidth label="Name" name="name" required />
      </DialogFormCard>
      <DialogFormCard
        title="Delete Organization"
        validationSchema={yup.object().shape({
          name: yup
            .string()
            .required()
            .test('name-match', `Id must match ${currentSelectedOrg?.pk}`, (value) => {
              return value ? currentSelectedOrg?.pk === Number.parseInt(value, 10) : false;
            }),
        })}
        values={{
          pk: currentSelectedOrg?.pk,
          name: '',
        }}
        width={matchesMd ? '500px' : '350px'}
        isOpen={deleteOrgFormOpen}
        setIsOpen={setDeleteOrgFormOpen}
        doSubmit={onDeleteOrganization}
        showCancel
        submitLabel="Delete"
        queryKey={ORG_DELETE_QUERY_KEY}
      >
        <Typography color="textPrimary">
          Enter organization id: {currentSelectedOrg?.pk} to confirm that you want to delete the organization. This
          operation can not be undone.
        </Typography>
        <Field component={TextField} fullWidth label="Enter Organization Id" name="name" required />
      </DialogFormCard>
      <Popper
        className={classes.moreActionsPopper}
        open={moreActionsOpen}
        anchorEl={moreActionsRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setMoreActionsOpen(false)}>
                <MenuList autoFocusItem={moreActionsOpen}>
                  <MenuItem
                    onClick={() => {
                      setMoreActionsOpen(false);
                      setEditOrgFormOpen(true);
                    }}
                  >
                    Edit Organization...
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMoreActionsOpen(false);
                      setDeleteOrgFormOpen(true);
                    }}
                  >
                    Delete Organization...
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
    </div>
  );
};
