/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Material-UI imports
import { ListItem, ListItemText } from '@material-ui/core';

// Other Third party imports
import { Column } from 'material-table';

// API imports
import { FoldableDevice } from '@wisi-tv/okapi-api';

// Common imports
import { MaterialTable } from '../../../../../components';
import { Licenses, More } from '../../../../../components/icons';

// Local imports
import { DeviceWithSearchableModules } from '../types';

export interface DevicesTableProps {
  /**
   * Optional className to provide styling at the root level.
   */
  className?: string;

  /**
   * List of devices to display in table.
   */
  devices: FoldableDevice[];

  /**
   * Indicates if the loggedInUser has org admin access.
   */
  hasOrgAdminAccess: boolean;

  /**
   * Is paging enabled or not for this table.
   */
  paging?: boolean;

  /**
   * Page size if paging is enabled.
   */
  pageSize?: number;

  /**
   * Is the table being rendered on desktop.  If we leave it up to this
   * component to do the media query then we run the risk of an
   * unnecessary extra render when if it wrongly detects a change.
   */
  isDesktop: boolean;

  /**
   * Called when the button to download a license is clicked.
   */
  onGetLicense: (event: React.MouseEvent<HTMLButtonElement>, selectedRows: DeviceWithSearchableModules[]) => void;

  /**
   * Called when the button to edit a member is clicked.
   */
  onMoreActions: (event: React.MouseEvent<HTMLButtonElement>, selectedRows: DeviceWithSearchableModules[]) => void;
}

/**
 * Table used to present all of the devices.
 */
export const DevicesTable: FC<DevicesTableProps> = (props: DevicesTableProps) => {
  const { isDesktop } = props;

  const columns: Column<DeviceWithSearchableModules>[] = [
    {
      title: 'Device',
      field: 'partNumber',
      searchable: true,
      hidden: false,
      render: (rowData) => {
        return (
          <ListItem disableGutters={rowData.parentId === undefined} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <ListItemText
              primary={isDesktop ? rowData.partNumber : `${rowData.partNumber} (${rowData.serialNumber})`}
              secondary={rowData.description}
            />
          </ListItem>
        );
      },
    },
    {
      title: 'Series',
      field: 'name',
      searchable: true,
      hidden: !props.isDesktop,
    },
    {
      title: 'Description',
      field: 'description',
      searchable: true,
      hidden: true,
    },
    {
      title: 'Serial',
      field: 'serialNumber',
      searchable: true,
      hidden: !props.isDesktop,
      width: '150px',
    },
    { field: 'children', searchable: false, hidden: true },
    { field: 'childrenJSON', searchable: true, hidden: true },
  ];

  const actions = [
    (rowData: DeviceWithSearchableModules[] | DeviceWithSearchableModules) => {
      const hidden = !Array.isArray(rowData);

      return {
        hidden,
        icon: () => <Licenses color="primary" />,
        tooltip: 'Download License Package',
        onClick: (
          event: React.MouseEvent<HTMLButtonElement>,
          selection: DeviceWithSearchableModules | DeviceWithSearchableModules[]
        ) => {
          props.onGetLicense(event, Array.isArray(selection) ? selection : [selection]);
        },
      };
    },
    (rowData: DeviceWithSearchableModules[] | DeviceWithSearchableModules) => {
      return {
        hidden: Array.isArray(rowData),
        icon: () => <More color="primary" />,
        tooltip: 'More actions',
        onClick: (
          event: React.MouseEvent<HTMLButtonElement>,
          selection: DeviceWithSearchableModules | DeviceWithSearchableModules[]
        ) => {
          props.onMoreActions(event, Array.isArray(selection) ? selection : [selection]);
        },
      };
    },
  ];

  /**
   * Reshape the incoming data to be what's needed for Material-Table to allow for grouping.
   */
  const searchableDevices = (): DeviceWithSearchableModules[] => {
    const ret: DeviceWithSearchableModules[] = [];

    const sortedDevices = props.devices
      .map((device) => {
        return {
          ...device,
          childrenJSON: JSON.stringify(device.children),
        };
      })
      .sort((a: FoldableDevice, b: FoldableDevice) => {
        const aLower = a.partNumber?.toLowerCase();
        const bLower = b.partNumber?.toLowerCase();
        if (aLower === undefined || bLower === undefined) return 0;
        if (aLower < bLower) return -1;
        if (aLower > bLower) return 1;
        return 0;
      });

    sortedDevices.forEach((device) => {
      ret.push({ ...device, parentId: undefined });
      device.children.forEach((child) => {
        ret.push({ ...child, childrenJSON: '', parentId: device.serialNumber, isAssembly: false, children: [] });
      });
    });

    return ret;
  };

  const devices: DeviceWithSearchableModules[] = searchableDevices();

  return (
    <div className={props.className}>
      <MaterialTable
        // parentChildData can skip looking if the current row's parentId is undefined because that is only true
        // for top level devices, in which case there is no need to look through all the rows for a serial number
        // that matches it.
        parentChildData={(row, rows) => rows.find((a) => row.parentId && a.serialNumber === row.parentId)}
        options={{
          maxBodyHeight: 'calc(100vh - 229px)',
          actionsColumnIndex: -1,
          selection: false,
          showSelectAllCheckbox: true,
          searchFieldVariant: 'outlined',
          showTextRowsSelected: false,
          searchFieldStyle: { width: props.isDesktop ? '500px' : '200px' },
          paging: props.paging,
          pageSize: props.pageSize,
          pageSizeOptions: [props.pageSize || 0],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: 'Search series, serial number, modules, ...',
          },
        }}
        searchFieldAlignment="left"
        columns={columns}
        data={devices}
        actions={actions}
      />
    </div>
  );
};

DevicesTable.defaultProps = {
  pageSize: 50,
} as Partial<DevicesTableProps>;
