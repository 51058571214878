/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Material-UI imports
import { Checkbox, FormControlLabel, Link, Typography } from '@material-ui/core';
import { SystemUpdateAltOutlined } from '@material-ui/icons';

// Other Third party imports
import { Column } from 'material-table';

// Common imports
import { MaterialTable } from '../../../../../components';
import { More } from '../../../../../components/icons';

// Local imports
import { FilterableEntry } from '../types';

export interface ProductsTableProps {
  /**
   * Optional className to provide styling at the root level.
   */
  className?: string;

  /**
   * List of entries to display in table.
   */
  entries: FilterableEntry[];

  /**
   * Is paging enabled or not for this table.
   */
  paging?: boolean;

  /**
   * Page size if paging is enabled.
   */
  pageSize?: number;

  /**
   * State setter that is used to set/clear the owned filter.
   */
  setIsFilterByOwned: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * Do we want to filter entries to only show entries that are for a product that the org owns.
   */
  isFilterByOwned?: boolean;

  /**
   * Is the table being rendered on desktop.  If we leave it up to this
   * component to do the media query then we run the risk of an
   * unnecessary extra render when if it wrongly detects a change.
   */
  isDesktop: boolean;

  /**
   * Called when the button to get more actions is clicked.
   */
  onMore: (event: React.MouseEvent<HTMLButtonElement>, selectedRows: FilterableEntry[]) => void;
}

/**
 * Table used to present all of the product resources.
 */
export const ProductsTable: FC<ProductsTableProps> = (props: ProductsTableProps) => {
  const { isFilterByOwned, setIsFilterByOwned, isDesktop } = props;

  const columns: Column<FilterableEntry>[] = [
    {
      title: 'Category',
      field: 'category',
      searchable: true,
      hidden: true,
      width: '50px',
      defaultGroupOrder: 0,
      defaultGroupSort: 'desc',
      render: (data: number | FilterableEntry) => {
        // If rendering a group then the data passed in will be a number,
        // otherwise it will be the row
        if (typeof data === 'number') {
          return <Typography variant="inherit">{data === 1 ? 'Firmware' : 'Manuals'}</Typography>;
        }
          return <Typography variant="inherit">{data.category === 1 ? 'Firmware' : 'Manuals'}</Typography>;

      },
    },
    {
      title: 'Resource Name',
      field: 'resourceName',
      searchable: true,
      hidden: false,
      width: '400px',
    },
    {
      title: 'Description',
      field: 'description',
      searchable: true,
      hidden: false,
    },
    {
      title: 'Product List',
      field: 'productList',
      searchable: true,
      hidden: true,
    },
    {
      title: 'Owned',
      type: 'boolean',
      field: 'owned',
      defaultFilter: props.isFilterByOwned ? 'checked' : undefined,
      searchable: true,
      hidden: true,
    },
  ];

  const actions = [
    {
      isFreeAction: true,
      onClick: () => {},
      icon: () => (
        <FormControlLabel
          control={
            <Checkbox
              checked={isFilterByOwned}
              onChange={() => setIsFilterByOwned(!isFilterByOwned)}
              name="checkedB"
              color="primary"
            />
          }
          label={isDesktop ? 'Show only owned devices' : 'Only owned'}
        />
      ),
    },
    (rowData: FilterableEntry | FilterableEntry[]) => {
      let target: string | undefined;
      let href = '#';

      if (!Array.isArray(rowData)) {
        if (rowData.category === 2) {
          target = '_blank';
        }
        href = rowData.latestVersion;
      }

      return {
        icon: () => (
          <Link href={href} target={target}>
            <SystemUpdateAltOutlined color="primary" />
          </Link>
        ),
        tooltip: 'Download latest',
        onClick: () => {},
      };
    },
    () => {
      return {
        icon: () => <More color="primary" />,
        tooltip: 'Download older versions',
        onClick: (event: React.MouseEvent<HTMLButtonElement>, selection: FilterableEntry | FilterableEntry[]) => {
          props.onMore(event, Array.isArray(selection) ? selection : [selection]);
        },
      };
    },
  ];

  return (
    <div className={props.className}>
      <MaterialTable
        options={{
          maxBodyHeight: 'calc(100vh - 250px)',
          thirdSortClick: false,
          actionsCellStyle: {
            maxWidth: '100px',
          },
          selection: false,
          actionsColumnIndex: -1,
          showSelectAllCheckbox: true,
          searchFieldVariant: 'outlined',
          showTextRowsSelected: false,
          searchFieldStyle: { width: props.isDesktop ? '500px' : '200px' },
          paging: props.paging,
          pageSize: props.pageSize,
          pageSizeOptions: [props.pageSize || 0],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: props.isDesktop ? 'Search part, module, firmware, manual name, ...' : 'Search',
          },
        }}
        searchFieldAlignment="left"
        columns={columns}
        actions={actions}
        data={props.entries}
      />
    </div>
  );
};

ProductsTable.defaultProps = {
  pageSize: 50,
} as Partial<ProductsTableProps>;

