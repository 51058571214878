/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { FC } from 'react';
import { Card as MuiCard, CardProps as MuiCardProps, makeStyles, Theme, createStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface CardProps extends MuiCardProps {
  /**
   * Optional className to pass forward
   */
  className?: string;

  /**
   * This is the width to use for the card - CSS string width.  Defaults to the width of the parent.
   */
  width?: string;

  elevation?: number;

  /**
   * Is the card transparent?
   */
  transparent?: boolean;

  /**
   * Optional avatar link to an image for the header
   */
  avatar?: string;

  /**
   * Is there padding in the content of the card.
   */
  contentPadding?: boolean;

  /**
   * Outline the card????
   */
  hoverVariant?: 'none' | 'raise' | 'outline';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: CardProps) => ({
      backgroundColor: props.transparent ? 'transparent' : theme.palette.grey[500],
      width: props.width,
      borderRadius: 0,
      marginBottom: theme.spacing(2),
      '& .MuiToolbar-root': {
        paddingLeft: theme.spacing(1),
      },
      '& .MuiCardContent-root': {
        padding: props.contentPadding ? theme.spacing(2) : 0,
        paddingBottom: props.contentPadding ? theme.spacing(3) : 0,
      },
      '& .MuiCardHeader-root': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
      },
      '&:hover': {
        boxShadow: props.hoverVariant === 'raise' ? theme.shadows[6] : theme.shadows[0],
        borderStyle: props.hoverVariant === 'outline' ? 'solid' : 'none',
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
      },
      '& .MuiCardActions-root': {
        marginBottom: theme.spacing(2),
      },
    }),
  })
);

export const Card: FC<CardProps> = ({ hoverVariant, contentPadding, ...otherProps }: CardProps) => {
  const classes = useStyles({ hoverVariant, contentPadding, ...otherProps });

  return (
    <MuiCard {...otherProps} className={clsx(otherProps.className, classes.root)}>
      {otherProps.children}
    </MuiCard>
  );
};

Card.defaultProps = {
  width: '100%',
  contentPadding: true,
  elevation: 0,
  hoverVariant: 'raise',
} as Partial<CardProps>;
