/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const More = createSvgIcon(
  <svg viewBox="0 0 6 24">
    <g id="WISI-Cloud" stroke="none">
      <g id="Desktop-HD-Copy" transform="translate(-1206.000000, -326.000000)">
        <g id="more" transform="translate(1206.000000, 326.000000)">
          <circle id="Oval" cx="2.633148" cy="2.61275641" r="2.61275641" />
          <circle id="Oval" cx="2.633148" cy="11.7573732" r="2.61275641" />
          <circle id="Oval" cx="2.633148" cy="20.9019901" r="2.61275641" />
        </g>
      </g>
    </g>
  </svg>,
  'More'
);
