/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { FC, useState } from 'react';
import { IonPage } from '@ionic/react';
import { Box, makeStyles, createStyles, useTheme, Hidden, Link, Typography } from '@material-ui/core';
import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { Logo } from '../logo';
import signin_image1 from './signin_image1.png';
import signin_image2 from './signin_image2.jpg';

export const CLIP_PATH_NONE = '';
export const CLIP_PATH_SLICE = 'polygon(70% 0, 100% 0, 100% 100%, 30% 100%)';

export interface CoverPageProps extends IonicReactProps {
  padding?: boolean;

  /**
   * This is the image to place behind the clip path.
   */
  backgroundImageUrl?: string;

  /**
   * This is the CSS clip-path property.
   */
  clipPath?: string;

  /**
   * Can be any react elements.
   */
  children?: React.ReactNode;

  /**
   * Terms and conditions link.
   */
  termsAndConditionsUrl?: string;

  hideLogo?: boolean;

  hideTerms?: boolean;
}

const images = [signin_image1, signin_image2];

const useStyles = makeStyles(() => {
  return createStyles({
    root: ({ clipPath, imageIndex }: { clipPath?: string; imageIndex?: number }) => ({
      backgroundImage: imageIndex !== undefined ? `url(${images[imageIndex]})` : undefined,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      clipPath: `${clipPath}`,
    }),
  });
});

export const CoverPage: FC<CoverPageProps> = ({
  clipPath = CLIP_PATH_SLICE,
  padding,
  termsAndConditionsUrl = '#',
  hideLogo,
  hideTerms,
  ...otherProps
}: CoverPageProps) => {
  const imageIndex = useState(Math.floor(Math.random() * images.length))[0];
  const theme = useTheme();
  const classes = useStyles({
    clipPath,
    imageIndex,
  });

  return (
    <IonPage style={{ height: '100%', background: 'white' }} {...otherProps}>
      {/* Use for tablet and desktop */}
      <Hidden xsDown>
        <Box
          position="absolute"
          left={padding && theme.spacing(8)}
          top={padding && theme.spacing(8)}
          zIndex={theme.zIndex.modal + 2}
          height="100%"
          width="100%"
        >
          {!hideLogo && <Logo link="https://wisi.tv" variant="medium" />}
          {!hideLogo && <Box height={theme.spacing(2)} />}
          {otherProps.children}
        </Box>
        {!hideTerms && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            position="absolute"
            zIndex={theme.zIndex.modal + 3}
            bottom={theme.spacing(1)}
            left={theme.spacing(1)}
          >
            <Typography variant="body1">© 2008-2020</Typography>
            <Link
              color="textPrimary"
              style={{ lineHeight: 1.5, marginLeft: `${theme.spacing(2)}px` }}
              href={termsAndConditionsUrl}
              target="_blank"
            >
              Terms and Conditions
            </Link>
          </Box>
        )}
      </Hidden>
      {/* Use for mobile */}
      <Hidden smUp>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="absolute"
          zIndex={theme.zIndex.modal + 2}
          height="100%"
          width="100%"
          style={{ backgroundColor: theme.palette.secondary.main }}
        >
          {!hideLogo && <Logo link="https://wisi.tv" variant="medium" />}
          <Box height={theme.spacing(2)} />
          {otherProps.children}
        </Box>
        {!hideTerms && (
          <Box
            display="flex"
            flexDirection="row"
            zIndex={theme.zIndex.modal + 3}
            justifyContent="center"
            position="absolute"
            bottom={theme.spacing(1)}
            left={theme.spacing(1)}
          >
            <Typography variant="body1">© 2008-2020</Typography>
            <Link
              color="textPrimary"
              style={{ lineHeight: 1.5, marginLeft: `${theme.spacing(2)}px` }}
              href={termsAndConditionsUrl}
              target="_blank"
            >
              Terms and Conditions
            </Link>
          </Box>
        )}
      </Hidden>
      {/* Clipped background image - only show on desktop and tablets */}
      <Hidden xsDown>
        <Box
          position="absolute"
          top="0"
          left="0"
          zIndex={theme.zIndex.modal + 1}
          className={classes.root}
          height="100%"
          width="100%"
        />
      </Hidden>
    </IonPage>
  );
};
