/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Document = createSvgIcon(
  <g id="WISI-portal" stroke="none">
    <g id="Desktop-HD" transform="translate(-33.000000, -271.000000)">
      <g id="document" transform="translate(33.000000, 271.000000)">
        <path
          d="M15.1313748,3.68710121 L11.6477404,0.203425992 C11.5166545,0.073932264 11.3400907,0.000938952 11.155852,0 L0.704908008,0 C0.320100984,0 0.0081648,0.311936184 0.0081648,0.696743208 L0.0081648,18.8116992 C0.0081648,19.1965062 0.320100984,19.5084424 0.704908008,19.5084424 L14.6394864,19.5084424 C15.0242934,19.5084424 15.3362296,19.1965062 15.3362296,18.8116992 L15.3362296,4.1803776 C15.3352498,3.99544488 15.261685,3.81830954 15.1313748,3.68710121 Z M11.8525544,2.37865118 L12.9575784,3.48367522 L11.8525544,3.48367522 L11.8525544,2.37865118 Z M13.942784,18.114956 L1.40161039,18.114956 L1.40161039,1.39344559 L10.4591088,1.39344559 L10.4591088,4.1803776 C10.4591088,4.56518462 10.771045,4.87712081 11.155852,4.87712081 L13.942784,4.87712081 L13.942784,18.114956 L13.942784,18.114956 Z"
          id="Shape"
         />
        <path
          d="M11.8525544,6.2705664 L3.49179919,6.2705664 C3.10699217,6.2705664 2.79505598,6.58250258 2.79505598,6.96730961 C2.79505598,7.35211663 3.10699217,7.66405282 3.49179919,7.66405282 L11.8525544,7.66405282 C12.2373614,7.66405282 12.5492976,7.35211663 12.5492976,6.96730961 C12.5492976,6.58250258 12.2373614,6.2705664 11.8525544,6.2705664 Z"
          id="Path"
         />
        <path
          d="M11.8525544,9.05749841 L3.49179919,9.05749841 C3.10699217,9.05749841 2.79505598,9.36943459 2.79505598,9.75424162 C2.79505598,10.1390486 3.10703299,10.450944 3.49179919,10.450944 L11.8525544,10.450944 C12.2373614,10.450944 12.5492976,10.1390078 12.5492976,9.75420079 C12.5492976,9.36939377 12.2373614,9.05749841 11.8525544,9.05749841 Z"
          id="Path"
         />
        <path
          d="M11.8525544,11.8443896 L3.49179919,11.8443896 C3.10699217,11.8443896 2.79505598,12.1563258 2.79505598,12.5411328 C2.79505598,12.9259398 3.10699217,13.237876 3.49179919,13.237876 L11.8525544,13.237876 C12.2373614,13.237876 12.5492976,12.9259398 12.5492976,12.5411328 C12.5492976,12.1563258 12.2373614,11.8443896 11.8525544,11.8443896 Z"
          id="Path"
         />
        <path
          d="M11.8525544,14.6313216 L3.49179919,14.6313216 C3.10699217,14.6313216 2.79505598,14.9432578 2.79505598,15.3280648 C2.79505598,15.7128718 3.10699217,16.024808 3.49179919,16.024808 L11.8525544,16.024808 C12.2373614,16.024808 12.5492976,15.7128718 12.5492976,15.3280648 C12.5492976,14.9432578 12.2373614,14.6313216 11.8525544,14.6313216 Z"
          id="Path"
         />
      </g>
    </g>
  </g>,
  'Document'
);
