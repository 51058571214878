/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Server = createSvgIcon(
  <g id="WISI-portal" stroke="none">
    <g id="Desktop-HD" transform="translate(-31.000000, -183.000000)">
      <g id="server-(1)" transform="translate(31.000000, 183.000000)">
        <path
          d="M19.456,1.824 C19.456,0.818216 18.637746,0 17.632,0 L1.824,0 C0.818254,0 0,0.818216 0,1.824 L0,5.472 C0,5.93883 0.176472,6.365038 0.465918,6.688 C0.176472,7.010962 0,7.43717 0,7.904 L0,11.552 C0,12.01883 0.176472,12.445038 0.465918,12.768 C0.176472,13.090962 0,13.51717 0,13.984 L0,17.632 C0,18.637784 0.818254,19.456 1.824,19.456 L17.632,19.456 C18.637746,19.456 19.456,18.637784 19.456,17.632 L19.456,13.984 C19.456,13.51717 19.279528,13.090962 18.990082,12.768 C19.279566,12.445038 19.456,12.01883 19.456,11.552 L19.456,7.904 C19.456,7.43717 19.279528,7.010962 18.990082,6.688 C19.279566,6.365038 19.456,5.93883 19.456,5.472 L19.456,1.824 Z M1.216,1.824 C1.216,1.488764 1.488764,1.216 1.824,1.216 L17.632,1.216 C17.967236,1.216 18.24,1.488764 18.24,1.824 L18.24,5.472 C18.24,5.807236 17.967236,6.08 17.632,6.08 L1.824,6.08 C1.488764,6.08 1.216,5.807236 1.216,5.472 L1.216,1.824 Z M18.24,7.904 L18.24,11.552 C18.24,11.887236 17.967236,12.16 17.632,12.16 L1.824,12.16 C1.488764,12.16 1.216,11.887236 1.216,11.552 L1.216,7.904 C1.216,7.568764 1.488764,7.296 1.824,7.296 L17.632,7.296 C17.967236,7.296 18.24,7.568764 18.24,7.904 Z M18.24,17.632 C18.24,17.967236 17.967236,18.24 17.632,18.24 L1.824,18.24 C1.488764,18.24 1.216,17.967236 1.216,17.632 L1.216,13.984 C1.216,13.648764 1.488764,13.376 1.824,13.376 L17.632,13.376 C17.967236,13.376 18.24,13.648764 18.24,13.984 L18.24,17.632 Z"
          id="Shape"
         />
        <path
          d="M16.416,4.864 C16.751768,4.864 17.024,4.591806 17.024,4.256 L17.024,3.04 C17.024,2.704194 16.751768,2.432 16.416,2.432 C16.080232,2.432 15.808,2.704194 15.808,3.04 L15.808,4.256 C15.808,4.591806 16.080232,4.864 16.416,4.864 Z"
          id="Path"
         />
        <path
          d="M13.984,4.864 C14.319768,4.864 14.592,4.591806 14.592,4.256 L14.592,3.04 C14.592,2.704194 14.319768,2.432 13.984,2.432 C13.648232,2.432 13.376,2.704194 13.376,3.04 L13.376,4.256 C13.376,4.591806 13.648232,4.864 13.984,4.864 Z"
          id="Path"
         />
        <path
          d="M6.688,4.256 L11.552,4.256 C11.887768,4.256 12.16,3.983806 12.16,3.648 C12.16,3.312194 11.887768,3.04 11.552,3.04 L6.688,3.04 C6.352232,3.04 6.08,3.312194 6.08,3.648 C6.08,3.983806 6.352232,4.256 6.688,4.256 Z"
          id="Path"
         />
        <path
          d="M3.648,10.944 C4.31851,10.944 4.864,10.39851 4.864,9.728 C4.864,9.05749 4.31851,8.512 3.648,8.512 C2.97749,8.512 2.432,9.05749 2.432,9.728 C2.432,10.39851 2.97749,10.944 3.648,10.944 Z"
          id="Path"
         />
        <path
          d="M3.648,14.592 C2.97749,14.592 2.432,15.13749 2.432,15.808 C2.432,16.47851 2.97749,17.024 3.648,17.024 C4.31851,17.024 4.864,16.47851 4.864,15.808 C4.864,15.13749 4.31851,14.592 3.648,14.592 Z"
          id="Path"
         />
        <path
          d="M3.648,4.864 C4.31851,4.864 4.864,4.31851 4.864,3.648 C4.864,2.97749 4.31851,2.432 3.648,2.432 C2.97749,2.432 2.432,2.97749 2.432,3.648 C2.432,4.31851 2.97749,4.864 3.648,4.864 Z"
          id="Path"
         />
        <path
          d="M16.416,10.944 C16.751768,10.944 17.024,10.671806 17.024,10.336 L17.024,9.12 C17.024,8.784194 16.751768,8.512 16.416,8.512 C16.080232,8.512 15.808,8.784194 15.808,9.12 L15.808,10.336 C15.808,10.671806 16.080232,10.944 16.416,10.944 Z"
          id="Path"
         />
        <path
          d="M13.984,10.944 C14.319768,10.944 14.592,10.671806 14.592,10.336 L14.592,9.12 C14.592,8.784194 14.319768,8.512 13.984,8.512 C13.648232,8.512 13.376,8.784194 13.376,9.12 L13.376,10.336 C13.376,10.671806 13.648232,10.944 13.984,10.944 Z"
          id="Path"
         />
        <path
          d="M6.688,10.336 L11.552,10.336 C11.887768,10.336 12.16,10.063806 12.16,9.728 C12.16,9.392194 11.887768,9.12 11.552,9.12 L6.688,9.12 C6.352232,9.12 6.08,9.392194 6.08,9.728 C6.08,10.063806 6.352232,10.336 6.688,10.336 Z"
          id="Path"
         />
        <path
          d="M16.416,14.592 C16.080232,14.592 15.808,14.864194 15.808,15.2 L15.808,16.416 C15.808,16.751806 16.080232,17.024 16.416,17.024 C16.751768,17.024 17.024,16.751806 17.024,16.416 L17.024,15.2 C17.024,14.864194 16.751768,14.592 16.416,14.592 Z"
          id="Path"
         />
        <path
          d="M13.984,14.592 C13.648232,14.592 13.376,14.864194 13.376,15.2 L13.376,16.416 C13.376,16.751806 13.648232,17.024 13.984,17.024 C14.319768,17.024 14.592,16.751806 14.592,16.416 L14.592,15.2 C14.592,14.864194 14.319768,14.592 13.984,14.592 Z"
          id="Path"
         />
        <path
          d="M11.552,15.2 L6.688,15.2 C6.352232,15.2 6.08,15.472194 6.08,15.808 C6.08,16.143806 6.352232,16.416 6.688,16.416 L11.552,16.416 C11.887768,16.416 12.16,16.143806 12.16,15.808 C12.16,15.472194 11.887768,15.2 11.552,15.2 Z"
          id="Path"
         />
      </g>
    </g>
  </g>,
  'Server'
);
