/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Tag = createSvgIcon(
  <g id="WISI-Cloud" stroke="none">
    <g id="Desktop-HD" transform="translate(-1212.000000, -182.000000)">
      <g id="bookmark" transform="translate(1212.000000, 182.000000)">
        <path
          d="M18.2003598,24.2820655 C17.9368992,24.2820655 17.6801454,24.1675893 17.5035087,23.9582934 L9.5894847,14.5750428 L1.6754607,23.9582934 C1.4287671,24.250914 1.0254843,24.3578583 0.6661602,24.2267841 C0.3067632,24.0954183 0.0676512,23.7535173 0.0676512,23.3708652 L0.0676512,0.91125 C0.0676512,0.4080213 0.4756725,0 0.9789012,0 L18.2000682,0 C18.703224,0 19.1113182,0.4080213 19.1113182,0.91125 L19.1113182,23.3707194 C19.1113182,23.7533715 18.8722062,24.0952725 18.5128092,24.2266383 C18.4106034,24.2639631 18.3048984,24.2820655 18.2003598,24.2820655 Z M9.5894847,12.2503347 C9.8580483,12.2503347 10.1129067,12.3687972 10.2860442,12.5740836 L17.2888911,20.8768833 L17.2888911,1.8225 L1.8901512,1.8225 L1.8901512,20.8768833 L8.8929252,12.5740836 C9.0660627,12.3687972 9.3209211,12.2503347 9.5894847,12.2503347 Z"
          id="Shape"
         />
      </g>
    </g>
  </g>,
  'Tag'
);
