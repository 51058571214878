/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import './configuration'; // This needs to be done first to make sure
// our redux configuration is done before any other imports that may use it

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import { config } from '@fortawesome/fontawesome-svg-core';
import { App } from './App';

import { store } from './store';

// https://fontawesome.com/how-to-use/on-the-web/other-topics/security
// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false

const Root = (): JSX.Element => (
  <Provider store={store}>
    <ReduxQueryProvider queriesSelector={(state) => state.queries}>
      <App />
    </ReduxQueryProvider>
  </Provider>
);

ReactDOM.render(<Root />, document.querySelector('#root'));
