/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Third party imports
import { Field } from 'formik';
import { ReduxQueryDispatch } from 'redux-query';

// API imports
import { MemberDetails, OrganizationSummary } from '@wisi-tv/okapi-api';

// Common imports
import { DialogFormCard, TextField } from '../../../../../components';

// Local imports
import { doEditTags } from '../operations';
import { TagsValues } from '../types';

export interface EditTagFormProps {
  /**
   * useState setter that is used to set the open/close state of the dialog.
   */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * useState getter that is used to indicate the current open/close state of the dialog.
   */
  isOpen: boolean;

  /**
   * Initial tag values to populate the form with.
   */
  tagValues: TagsValues;

  /**
   * Identifies the current authenticated user.
   */
  loggedInUserPk?: number;

  /**
   * Indicates the current selected organization.
   */
  selectedOrg: OrganizationSummary | null;

  /**
   * Indicates which rows are being updated in the table.
   */
  selectedRows: MemberDetails[];

  /**
   * Optional dispatcher to perform the change on the backend.
   */
  dispatch?: ReduxQueryDispatch<unknown>;
}

/**
 * Form responsible for editing tags of one or more members in an organization.
 */
export const EditTagForm: FC<EditTagFormProps> = (props: EditTagFormProps) => {
  return (
    <DialogFormCard<TagsValues>
      queryKey="tagUpdate"
      setIsOpen={props.setIsOpen}
      isOpen={props.isOpen}
      values={props.tagValues}
      checkDirty={false}
      doSubmit={async (values: TagsValues) =>
        props.dispatch && doEditTags(props.dispatch, props.selectedOrg, props.selectedRows, values)
      }
      submitLabel="Set Tags"
      title="Edit tag for the selected users"
    >
      <Field component={TextField} fullWidth label="Tag" name="tag" />
    </DialogFormCard>
  );
};

