/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Edit = createSvgIcon(
  <g id="WISI-portal" stroke="none">
    <g id="Desktop-HD" transform="translate(-1230.000000, -315.000000)">
      <g id="edit_icon" transform="translate(1225.000000, 308.000000)">
        <polygon id="Shape" points="0 0 31.59 0 31.59 31.59 0 31.59" fill="none" />
        <path
          d="M5.28109826,18.7590915 L5.265,21.9789506 C5.265,22.1603657 5.33104416,22.3417807 5.46333887,22.4740755 C5.59563358,22.6063702 5.75991842,22.6724143 5.94215903,22.6724143 L9.14530079,22.6563161 C9.32671584,22.6563161 9.49182624,22.5900655 9.62412095,22.4579772 L20.686105,11.3951676 C20.950488,11.1316101 20.950488,10.7019103 20.686105,10.4212226 L17.5159853,7.21828727 C17.2516023,6.95390424 16.822728,6.95390424 16.5422467,7.21828727 L14.3299737,9.44748406 L5.47943713,18.2802713 C5.36406624,18.4123596 5.28109826,18.5776764 5.28109826,18.7590915 Z M17.0371651,8.6710524 L19.2663619,10.9002492 L18.0119357,12.1546755 L15.7827389,9.92547867 L17.0371651,8.6710524 Z M24.0213351,24.7532181 C24.401089,24.7532181 24.7145923,25.0667215 24.7145923,25.4295516 C24.7145923,25.7932073 24.4180128,26.1067106 24.0382589,26.1067106 L24.0382589,26.1067106 L5.95825729,26.1067106 C5.59563358,26.1067106 5.28109826,25.7932073 5.28109826,25.4295516 C5.28109826,25.0667215 5.57870976,24.7532181 5.95825729,24.7532181 L5.95825729,24.7532181 Z M14.8079683,10.9002492 L17.0371651,13.129446 L8.88091776,21.2687695 L6.63562271,21.2856934 L6.65172097,19.0564966 L14.8079683,10.9002492 Z"
          id="Fill-2"
         />
      </g>
    </g>
  </g>,
  'Edit'
);
