/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Material-UI imports
import { FormControl, InputLabel, MenuItem, useTheme } from '@material-ui/core';

// Other Third party imports
import { Field } from 'formik';
import { ReduxQueryDispatch } from 'redux-query';

// API imports
import { MemberDetails, OrganizationSummary, Role } from '@wisi-tv/okapi-api';

// Common imports
import { DialogFormCard, Select, TextField } from '../../../../../components';

// Local imports
import { doEditMember } from '../operations';
import { EditValues } from '../types';
import { convertRole } from '../utils';

export interface EditMemberFormProps {
  /**
   * useState setter that is used to set the open/close state of the dialog.
   */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * useState getter that is used to indicate the current open/close state of the dialog.
   */
  isOpen: boolean;

  /**
   * Initial member values to populate the form with.
   */
  memberValues: EditValues;

  /**
   * List of possible roles - comes from the backend.
   */
  roles: Role[];

  /**
   * Identifies the current authenticated user.
   */
  loggedInUserPk?: number;

  /**
   * Indicates the current selected organization.
   */
  selectedOrg: OrganizationSummary | null;

  /**
   * Indicates which rows are being updated in the table.
   */
  selectedRows: MemberDetails[];

  /**
   * Optional dispatcher to perform the change on the backend.
   */
  dispatch?: ReduxQueryDispatch<unknown>;
}

/**
 * Form responsible for editing attributes of one or more members in an organization.
 */
export const EditMemberForm: FC<EditMemberFormProps> = (props: EditMemberFormProps) => {
  const theme = useTheme();

  return (
    <DialogFormCard<EditValues>
      queryKey="memberUpdate"
      setIsOpen={props.setIsOpen}
      isOpen={props.isOpen}
      values={props.memberValues}
      checkDirty={false}
      doSubmit={async (values: EditValues) => {
        if (props.dispatch)
          doEditMember(props.dispatch, props.selectedOrg, props.selectedRows, values, props.loggedInUserPk);
      }}
      submitLabel="Submit"
      title="Edit the selected users"
    >
      <Field component={TextField} fullWidth label="Tag" name="tag" />
      <FormControl
        disabled={props.memberValues.userPk === props.loggedInUserPk}
        style={{ marginTop: `${theme.spacing(1)}px` }}
        variant="outlined"
        fullWidth
      >
        <InputLabel>Role</InputLabel>
        <Field component={Select} name="role" label="Role">
          {props.roles &&
            props.roles.map((role) => (
              <MenuItem key={role.pk} value={role.pk}>
                {convertRole(role.name)}
              </MenuItem>
            ))}
        </Field>
      </FormControl>
    </DialogFormCard>
  );
};

