/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Material-UI imports
import { FormControl, InputLabel, MenuItem, useTheme } from '@material-ui/core';

// Third party imports
import { Field } from 'formik';
import { ReduxQueryDispatch } from 'redux-query';

// API imports
import { OrganizationSummary, Role } from '@wisi-tv/okapi-api';

// Common imports
import { DialogFormCard, Select, TextField } from '../../../../../components';

// Local imports
import { doInvite } from '../operations';
import { InviteValidationSchema, InviteValues } from '../types';
import { convertRole, getUserRole } from '../utils';

export interface InviteFormProps {
  /**
   * useState setter that is used to set the open/close state of the dialog.
   */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  /**
   * useState getter that is used to indicate the current open/close state of the dialog.
   */
  isOpen: boolean;

  /**
   * List of possible roles - comes from the backend.
   */
  roles: Role[];

  /**
   * Indicates the current selected organization.
   */
  selectedOrg: OrganizationSummary | null;

  /**
   * Optional dispatcher to perform the change on the backend.
   */
  dispatch?: ReduxQueryDispatch<unknown>;
}

/**
 * Form responsible for editing tags of one or more members in an organization.
 */
export const InviteForm: FC<InviteFormProps> = (props: InviteFormProps) => {
  const theme = useTheme();

  return (
    <DialogFormCard<InviteValues>
      queryKey="sendInvite"
      setIsOpen={props.setIsOpen}
      isOpen={props.isOpen}
      values={{
        firstName: '',
        lastName: '',
        email: '',
        role: getUserRole(props.roles),
      }}
      validationSchema={InviteValidationSchema}
      doSubmit={async (values: InviteValues) => props.dispatch && doInvite(props.dispatch, props.selectedOrg, values)}
      submitLabel="Send Invite"
      title={props.selectedOrg?.name}
      subTitle="Invite new user"
    >
      <Field component={TextField} fullWidth label="First Name" name="firstName" required />
      <Field component={TextField} fullWidth label="Last Name" name="lastName" />
      <Field component={TextField} fullWidth label="Email" name="email" required />
      <FormControl style={{ marginTop: `${theme.spacing(1)}px` }} variant="outlined" fullWidth>
        <InputLabel>Role</InputLabel>
        <Field component={Select} name="role" label="Role">
          {props.roles &&
            props.roles.map((role) => (
              <MenuItem key={role.pk} value={role.pk}>
                {convertRole(role.name)}
              </MenuItem>
            ))}
        </Field>
      </FormControl>
    </DialogFormCard>
  );
};

