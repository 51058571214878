/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';

// Material-UI imports
import { Container, Typography, Box, useTheme, CircularProgress, useMediaQuery } from '@material-ui/core';

// API imports
import { ContentEntrySummary, Device, entriesList } from '@wisi-tv/okapi-api';

// Common imports
import { AppState } from '../../../../store';

// Local imports
import { FilterableEntry } from './types';
import { MoreActionsMenu, ProductsTable } from './components';

export interface ProductsProps {
  hide?: boolean;
}

/**
 * Main tool used for displaying the product resources.
 */
export const Products: FC<ProductsProps> = (props: ProductsProps) => {
  /** **************************************************************************
   *  Initial theme and hook setup
   ************************************************************************** */
  const theme = useTheme();
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));

  /** **************************************************************************
   * Redux query selectors and dispatchers
   ************************************************************************** */

  const entries = useSelector<AppState, ContentEntrySummary[]>((state: AppState) => state.entities.entriesList || []);

  const devices = useSelector<AppState, Device[]>((state: AppState) => state.entities.organizationsDevicesList || []);

  const productsLoading = useRequest(entriesList({}))[0];

  /** **************************************************************************
   * Local state
   ************************************************************************** */

  const [selectedEntry, setSelectedEntry] = useState<FilterableEntry | null>(null);
  const [isFilterByOwned, setIsFilterByOwned] = useState<boolean>(true);
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  /** **************************************************************************
   * Event handlers
   ************************************************************************** */

  const onMore = (event: React.MouseEvent<HTMLButtonElement>, entry: FilterableEntry | FilterableEntry[]): void => {
    if (Array.isArray(entry)) {
      setSelectedEntry(entry[0]);
    } else {
      setSelectedEntry(entry);
    }
    setMoreAnchorEl(event.currentTarget);
  };

  /** **************************************************************************
   * Local variables - typically derived from state and used in renders
   ************************************************************************** */

  const searchableEntries = useMemo(() => {
    const ret: FilterableEntry[] = [];
    const sortedEntries = entries.sort((a, b) => {
      const aLower = (a.name as string)?.toLowerCase();
      const bLower = (b.name as string)?.toLowerCase();
      if (aLower === undefined || bLower === undefined) return 0;
      if (aLower < bLower) return -1;
      if (aLower > bLower) return 1;
      return 0;
    });
    sortedEntries.forEach((entry) => {
      const owned = devices.some(device => {
        return (entry.productList && entry.productList.includes(device.partNumber));
      });

      let latestVersion = '#';
      for (let i = 0; i < entry.versions.length; i += 1) {
        if (entry.versions[i].isLatest) {
          latestVersion = entry.versions[i].url || '#';
        }
      }

      ret.push({
        category: entry.category,
        description: entry.description,
        resourceName: entry.name,
        productList: entry.productList || '',
        owned,
        latestVersion,
        versions: entry.versions,
      });
    });

    return ret;
  }, [devices, entries]);

  /** **************************************************************************
   * Render functions
   ************************************************************************** */

  /**
   * Render the main tool - this really only should get re-rendered if
   * the adminAccess level changes, the list of devices changes, or if
   * the media query determining the screen size changes.
   */
  const renderTool = useMemo(() => {
    return (
      <Container maxWidth="lg">
        <Typography color="primary" variant="h1">
          Resources
        </Typography>
        <ProductsTable
          isFilterByOwned={isFilterByOwned}
          setIsFilterByOwned={setIsFilterByOwned}
          isDesktop={mdMatches}
          entries={searchableEntries}
          onMore={onMore}
          paging={searchableEntries.length > 50}
          pageSize={50}
        />
        <MoreActionsMenu
          isDesktop={mdMatches}
          selectedEntry={selectedEntry}
          anchorEl={moreAnchorEl}
          setAnchorEl={setMoreAnchorEl}
        />
      </Container>
    );
  }, [searchableEntries, mdMatches, isFilterByOwned, moreAnchorEl, selectedEntry]);

  /**
   * Render what happens when we have no devices.  Only re-computed if
   * the theme changes.
   */
  const renderNoProducts = useMemo(() => {
    return (
      <Container fixed>
        <Box height={theme.spacing(20)} />
        <Typography variant="h1">No products visible with content.</Typography>
      </Container>
    );
  }, [theme]);

  /**
   * Render what happens during device loading.  This never needs to get
   * re-computed.
   */
  const renderLoading = useMemo(() => {
    return (
      <Container fixed>
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress />
        </Box>
      </Container>
    );
  }, []);

  /** **************************************************************************
   * Main Render
   ************************************************************************** */

  if (props.hide) {
    return <></>;
  }

  return (
    <>
      {productsLoading.isPending
        ? renderLoading
        : (searchableEntries.length > 0
        ? renderTool
        : renderNoProducts)}
    </>
  );
};
