/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const CircleFilled = createSvgIcon(
  <svg height="28" width="28">
    <circle cx="14" cy="14" r="8" strokeWidth="2" />
  </svg>,
  'CircleFilled'
);
