/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Framework = createSvgIcon(
  <g id="WISI-Cloud" stroke="none">
    <g id="framework">
      <path
        d="M16.9414502,4.14475776 L12.9877862,0.19109376 C12.8634517,0.06774768 12.6950916,-0.000988416 12.519936,-1.05142414e-05 L1.976832,-1.05142414e-05 C0.88504416,-1.05142414e-05 0,0.88504416 0,1.976832 L0,19.109376 C0,20.2011638 0.88504416,21.086208 1.976832,21.086208 L15.155712,21.086208 C16.2474998,21.086208 17.1325554,20.2011638 17.1325554,19.109376 L17.1325554,4.612608 C17.1335736,4.43745245 17.0647963,4.26913344 16.9414502,4.14475776 Z M15.814656,19.109376 C15.814656,19.473319 15.519655,19.76832 15.155712,19.76832 L1.976832,19.76832 C1.61288899,19.76832 1.317888,19.473319 1.317888,19.109376 L1.317888,1.976832 C1.317888,1.61288899 1.61288899,1.317888 1.976832,1.317888 L11.860992,1.317888 L11.860992,3.953664 C11.860992,4.68150883 12.4510352,5.271552 13.17888,5.271552 L15.814656,5.271552 L15.814656,19.109376 Z"
        id="Shape"
       />
      <path
        d="M6.12158976,8.75736576 L3.48581376,11.3931418 C3.23030822,11.6501711 3.23030822,12.0652235 3.48581376,12.3222528 L6.12158976,14.9580288 L7.0507008,14.0289178 L4.88277504,11.860992 L7.0507008,9.69306624 L6.12158976,8.75736576 Z"
        id="Path"
       />
      <path
        d="M10.0752538,9.69306624 L12.249769,11.860992 L10.0818432,14.0289178 L11.0109542,14.9580288 L13.6467302,12.3222528 C13.9022358,12.0652235 13.9022358,11.6501711 13.6467302,11.3931418 L11.0109542,8.75736576 L10.0752538,9.69306624 Z"
        id="Path"
       />
    </g>
  </g>,
  'Framework'
);
