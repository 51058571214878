/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const Licenses = createSvgIcon(
  <g id="WISI-Cloud" stroke="none">
    <g id="licenses">
      <g id="Group-3">
        <path
          d="M16.9414502,4.14477857 L12.9877862,0.191114567 C12.8634517,0.0677684875 12.6950916,-0.000967608523 12.519936,1.02932354e-05 L1.976832,1.02932354e-05 C0.88504416,1.02932354e-05 4.75939288e-13,0.885064967 4.75939288e-13,1.97685281 L4.75939288e-13,19.1093968 C4.75939288e-13,20.2011846 0.88504416,21.0862288 1.976832,21.0862288 L15.155712,21.0862288 C16.2474998,21.0862288 17.1325554,20.2011846 17.1325554,19.1093968 L17.1325554,4.61262881 C17.1335736,4.43747326 17.0647963,4.26915425 16.9414502,4.14477857 Z M15.814656,19.1093968 C15.814656,19.4733398 15.519655,19.7683408 15.155712,19.7683408 L1.976832,19.7683408 C1.61288899,19.7683408 1.317888,19.4733398 1.317888,19.1093968 L1.317888,1.97685281 C1.317888,1.6129098 1.61288899,1.31790881 1.976832,1.31790881 L11.860992,1.31790881 L11.860992,3.95368481 C11.860992,4.68152964 12.4510352,5.27157281 13.17888,5.27157281 L15.814656,5.27157281 L15.814656,19.1093968 Z"
          id="Shape"
         />
        <path
          d="M12.3396036,13.0579946 C12.7860249,12.5432399 13.0548008,11.8825515 13.0548008,11.1631336 C13.0548008,9.52376346 11.6599206,8.19 9.9453384,8.19 C8.23068175,8.19 6.83565259,9.5235499 6.83565259,11.1631336 C6.83565259,11.8824803 7.10442855,12.5431687 7.55092425,13.0579946 L5.89620241,16.0157517 C5.81519735,16.1602618 5.84259612,16.3384433 5.96358253,16.4547632 C6.08471785,16.5710119 6.27085079,16.5980631 6.42251191,16.5215368 L7.63803447,15.9075472 L7.77852761,17.2151137 C7.79207809,17.3409015 7.87025392,17.4485366 7.98267822,17.5059135 C8.01603324,17.5230696 8.0525153,17.5356697 8.0911565,17.543002 C8.25942067,17.5745379 8.42954618,17.4974422 8.51055124,17.3530034 L9.94518949,14.7887691 L11.3796044,17.3530034 C11.4604605,17.4974422 11.630586,17.5745379 11.7988502,17.543002 C11.8376403,17.5355985 11.8741224,17.5231408 11.9074774,17.5059135 C12.0198272,17.4485366 12.0982264,17.3407592 12.111628,17.2151137 L12.2523445,15.9075472 L13.4677926,16.5215368 C13.6191559,16.5981342 13.8055867,16.5710119 13.9265731,16.4547632 C14.0474106,16.3384433 14.0747349,16.1602618 13.9938788,16.0157517 L12.3396036,13.0579946 Z M9.94556175,12.6679599 C9.07766108,12.6679599 8.37154716,11.9929628 8.37154716,11.1631336 C8.37154716,10.3333043 9.07743772,9.65823609 9.94556175,9.65823609 C10.8132391,9.65823609 11.5194274,10.3330908 11.5194274,11.1631336 C11.5192785,11.9928204 10.8133135,12.6679599 9.94556175,12.6679599 Z"
          id="Shape"
         />
      </g>
    </g>
  </g>,
  'Licenses'
);
