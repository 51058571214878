/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import { configureStore } from '@reduxjs/toolkit';
import { AnyAction, CombinedState, combineReducers, Dispatch, Middleware } from 'redux';
import { entitiesReducer, queriesReducer, errorsReducer, queryMiddleware , actionTypes, QueriesState, EntitiesState, Entities, ErrorsState, CancelQueryAction, RequestAsyncAction, MutateAsyncAction, UpdateEntitiesAction } from 'redux-query';
import createSagaMiddleware from 'redux-saga';
import { superagentNetworkInterface } from './ReduxNetworkAgent';

// Add an import for each slice
import { systemReducer, SystemState } from './slices/system';
import { watchApiFailure } from './sagas';


const appReducer = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  errors: errorsReducer,
  system: systemReducer, // Add a line for each reducer
});

export const ACTION_CLEAR_STATE = 'CLEAR_STATE';
export const getQueries = (state: Record<string, QueriesState>):QueriesState => state.queries;
export const getEntities = (state: Record<string, EntitiesState>):EntitiesState => state.entities;

// This is a pattern used to reset Redux state.  You wrap your original rootReducer
// and then if a special CLEAR action is received then call the original reducer with
// undefined for the state.  Reducers must return the initial state if this is done.
//
// The ugly verbose typing is purely for EsLint and Typescript purposes.
const rootReducer = (
  state: CombinedState<{
    entities: Entities;
    queries: QueriesState;
    errors: ErrorsState;
    system: SystemState; }> |
            undefined,
  action: CancelQueryAction |
          RequestAsyncAction<Entities> |
          MutateAsyncAction<Entities> |
          UpdateEntitiesAction<Entities> |
          AnyAction): CombinedState<{
            entities: Entities;
            queries: QueriesState;
            errors: ErrorsState;
            system: SystemState; }> => {
  if (action.type === ACTION_CLEAR_STATE) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}


const sagaMiddleware = createSagaMiddleware();

const nextFunction = (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  if (action && action.type === actionTypes.MUTATE_ASYNC) {
    const csrfToken = localStorage.getItem('csrftoken');
    const options = (action.options || {}) as Record<string, unknown>;
    const headers = (options.headers || {}) as Record<string, unknown>;
    const updatedAction = {
      ...action,
      options: {
        ...options,
        headers: {
          ...headers,
          'X-CSRFToken': `${csrfToken}`,
        },
      },
    };

    // Let the action continue, but now with the JWT header.
    return next(updatedAction);
  }

  // This isn't a redux-query action so just let it pass through
  return next(action);
}

const csrfMiddleware: Middleware = () => nextFunction;


export const store = configureStore({
  reducer: rootReducer,
  middleware: [csrfMiddleware, queryMiddleware(superagentNetworkInterface, getQueries, getEntities), sagaMiddleware],
});

// Start watching for api failures
sagaMiddleware.run(watchApiFailure);

export type AppState = ReturnType<typeof store.getState>;
